// import React, { useEffect, useState } from "react";
// import { useSelector, useDispatch } from "react-redux";
// import Vehiclecard from "./vehiclecard";
// import "./choosevehicle.css";
// import { getVehicles, selectVehicles } from "../../redux/feature/vehicle/slice";

// const initialData = {
//   // Other vehicle attributes
//   type: "Chevrolet Silverado 1500",
//   name: "John's Commuter",
//   make: "Toyota",
//   model: "Camry",
//   year: "2020",
//   licenseNumber: "4ABC123",
//   color: "Silver",
//   VIN: "1HGBH41JXMN109186",
//   insuranceCompany: "Allstate",
//   policyNumber: "923456789",
//   insuranceExpiration: "2024-09-15",
//   tagsExpirationDate: "2024-06-30",
//   inspectionExpirationDate: "2024-06-15",
//   picture: "URL_to_a_picture_of_the_vehicle",
//   uploadedDocument: "URL_to_uploaded_document_of_vehicle_registration_or_insurance",
//   notes: "Leased vehicle; all maintenance records available; no accidents.",
//   features: {
//     general: {
//       ac: { isAvailable: false, name: "AC", icon: "material-symbols:ac-unit" },
//       bathroom: { isAvailable: false, name: "Bathroom", icon: "emojione-monotone:toilet" },
//       dancePole: { isAvailable: false, name: "Dance Pole", icon: "mdi:dance-pole" },
//       inVehicleBar: { isAvailable: false, name: "In-Vehicle Bar", icon: "carbon:bar" }, 
//       luggage: { isAvailable: true, name: "Luggage", icon: "fa6-solid:person-walking-luggage" },
//       refrigerator: { isAvailable: false, name: "Refrigerator", icon: "mdi:refrigerator" },
//       tables: { isAvailable: true, name: "Tables", icon: "material-symbols:table-bar-sharp" },
//       trashCan: { isAvailable: false, name: "Trash Can", icon: "mdi:trash-can-outline" },
//       wheelchairAccessible: { isAvailable: false, name: "Wheelchair Accessible", icon: "fa6-solid:wheelchair" },
//       iceChest: { isAvailable: false, name: "Ice Chest", icon: "game-icons:ice-cube" },
//     },
//     multimedia: {
//       aux: { isAvailable: false, name: "AUX", icon: "teenyicons:audio-cable-solid" },
//       bluetooth: { isAvailable: true, name: "Bluetooth", icon: "cib:bluetooth" },
//       dvdPlayer: { isAvailable: false, name: "DVD Player", icon: "cbi:dvd" },
//       gameConsole: { isAvailable: false, name: "Game Console", icon: "game-icons:console-controller" },
//       karaoke: { isAvailable: false, name: "Karaoke", icon: "maki:karaoke" },
//       tv: { isAvailable: false, name: "TV", icon: "ic:twotone-live-tv" },
//       usb: { isAvailable: false, name: "USB", icon: "ant-design:usb-filled" },
//       wifi: { isAvailable: true, name: "WiFi", icon: "mingcute:wifi-fill" },
//       powerOutlets: { isAvailable: false, name: "Power Outlets", icon: "icon-park-solid:energy-socket" },
//       appleCarplay: { isAvailable: false, name: "Apple Carplay", icon: "mingcute:apple-fill" },
//       androidCarplay: { isAvailable: false, name: "Android Carplay", icon: "mdi:android" },
      
//     },
//     policies: {
//       alcoholFriendly: { isAvailable: false, name: "Alcohol Friendly", icon: "healthicons:alcohol" },
//       petsAllowed: { isAvailable: false, name: "Pets Allowed", icon: "ic:round-pets" },
//       foodAllowed: { isAvailable: false, name: "Food Allowed", icon: "ion:fast-food-outline" },
//       smokingAllowed: { isAvailable: false, name: "Smoking Allowed", icon: "ic:twotone-smoking-rooms" },
//     },
//   },
// };
// function Choosevehicle({goNext, formData}) {

//   const dispatch = useDispatch();

//   // console.log("Form Data in Choosevehicle: ", formData);
//   console.log("Main ZipCode: ", formData?.pickupAddress?.zipCode);
//   console.log("Conn ZipCode: ", formData?.dropoffAddress?.zipCode);

  
//   const vehicles = useSelector(selectVehicles);

//   useEffect(() => {

//     const mainZipCode = formData?.pickupAddress?.zipCode;
//     const baseRateZipCode = formData?.dropoffAddress?.zipCode;

//     dispatch(getVehicles({ mainZipCode, baseRateZipCode}));

//   }, [dispatch]);

//   console.log("Vehicle Data:", vehicles);

//   const onNextClick = () => {
//     // Add any necessary logic before advancing the step
//     console.log("Next button clicked, performing necessary checks...");

//     // Call the goNext function to advance to the next step
//     goNext();
//   };

//   return (
//     <>
//       <div className="row d-flex col-11 mx-auto">
//         {[1, 2, 3, 4, 5, 6].map((item, index) => {
//           return (
//             <div key={index} className="col-lg-4 my-2 d-flex col-md-6 col-sm-12">
//               <div className="col-11 mx-auto">
//                 <Vehiclecard vehicle={initialData} goNext={onNextClick}/>
//               </div>
//             </div>
//           );
//         })}
//       </div>
//       <br />
//     </>
//   );
// }
// export default Choosevehicle;


import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Vehiclecard from "./vehiclecard";
import "./choosevehicle.css";
import { getVehicles, selectVehicles,getVehiclesHourlyRate } from "../../redux/feature/vehicle/slice";

function Choosevehicle({ goNext, formData, companyId }) {
  const dispatch = useDispatch();
  console.log("trip typee",formData);
  // Fetch vehicles from the Redux store
  const vehicles = useSelector(selectVehicles);

  useEffect(() => {
    const mainZipCodes = formData?.pickupAddress?.zipCode;
    const baseRateZipCodes = formData?.dropoffAddress?.zipCode;

    const zipCodes = {
      mainZipCode : mainZipCodes,
      baseRateZipCode : baseRateZipCodes
    };
//     console.log("trip typee",formData);
// if(formData?.tripType == "Hourly"){
//   const hours = formData?.totalTripDuration;
// dispatch(getVehiclesHourlyRate({companyId ,hours }));
// }
// else{
    // dispatch(getVehicles({ companyId, mainZipCode, baseRateZipCode }));
    dispatch(getVehicles({ companyId, zipCodes }));
  // }
  }, [dispatch, formData]);

  const onNextClick = (vehicle) => {
    goNext(vehicle);
  };

  return (
    <>
      <div className="row d-flex col-11 mx-auto">
        {vehicles?.vehicles?.map((vehicle, index) => (
          <div key={index} className="col-lg-4 my-2 d-flex col-md-6 col-sm-12">
            <div className="col-11 mx-auto">
              <Vehiclecard vehicle={vehicle} goNext={onNextClick} />
            </div>
          </div>
        ))}
      </div>
      <br />
    </>
  );
}

export default Choosevehicle;
