import Swal from "sweetalert2";

export const successAlert = (msg) => {
    Swal.fire({
      icon: "success",
      title: "Done",
      text: msg,
    });
  };
  
  export const errorAlert = (msg) => {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: msg,
    });
  };
  
  export const loadingAlert = (msg) => {
    // Show loading animation
    Swal.fire({
      title: "Processing...",
      html: msg,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
  };