import {
    getVehiclesByZipCode,getVehiclesByHourlyRate
  } from "../../../utils/api.js";
import { errorAlert, successAlert } from "../../../utils/helper.js";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const handleApiError = (error) =>
    error.response?.data || error.message || "An unexpected error occurred";


// export const getVehicles = createAsyncThunk(
//     "/vehicle-pricing/get-basepricebyzipcode",
//     async ({ companyId, mainZipCode, baseRateZipCode }, { rejectWithValue }) => {
//       try {
//         return await getVehiclesByZipCode(companyId, mainZipCode, baseRateZipCode);
//       } catch (error) {
//         return rejectWithValue(handleApiError(error));
//       }
//     }
// );
export const getVehicles = createAsyncThunk(
  "/vehicle-pricing/get-basepricebyzipcode",
  async ({ companyId, zipCodes }, { rejectWithValue }) => {
    try {
      return await getVehiclesByZipCode(companyId, zipCodes);
    } catch (error) {
      return rejectWithValue(handleApiError(error));
    }
  }
);

export const getVehiclesHourlyRate = createAsyncThunk(
  "/vehicle-pricing/get-hourlyRates",
  async ({ companyId, hours }, { rejectWithValue }) => {
    try {
      return await getVehiclesByHourlyRate(companyId, hours);
    } catch (error) {
      return rejectWithValue(handleApiError(error));
    }
  }
);



const initialState = {
    vehicles: [],
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
    saving:false,
};

const vehicleSlice = createSlice({
    name: 'vehicle',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
      builder

      .addCase(getVehicles.pending, (state) => {
        state.status = 'loading';
        state.vehicles = null;
      })
      .addCase(getVehicles.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.vehicles = action.payload.data;
        state.error = null; 
      })
      .addCase(getVehicles.rejected, (state, action) => {
        state.status = 'failed';
        state.vehicles = null;
        state.error = action.payload.message || 'Could not get vehicle pricing.';
      })
      .addCase(getVehiclesHourlyRate.pending, (state) => {
        state.status = 'loading';
        state.vehicles = null;
      })
      .addCase(getVehiclesHourlyRate.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.vehicles = action.payload.data;
        state.error = null; 
      })
      .addCase(getVehiclesHourlyRate.rejected, (state, action) => {
        state.status = 'failed';
        state.vehicles = null;
        state.error = action.payload.message || 'Could not get vehicle pricing.';
      });


    },
});

export const selectVehicles = (state) => state.vehicle.vehicles;

export default vehicleSlice.reducer;