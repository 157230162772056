import axios from "axios";

export const API = axios.create({
      //baseURL: "http://localhost:8081/",
 baseURL: "https://limo-server-404607.uc.r.appspot.com/",
});


// export const getVehiclesByZipCode = (companyId, mainZipCode, baseRateZipCode) => {
//   return API.get(
//     `/vehicle-pricing/get-basepricebyzipcode/${companyId}/${mainZipCode}/${baseRateZipCode}`
//   );
// };
export const getVehiclesByZipCode = (companyId, zipCodes) => {
  return API.post(
    `/vehicle-pricing/get-basepricebyzipcode/${companyId}`, zipCodes
  );
};
export const getVehiclesByHourlyRate = (companyId, hour) => {
  return API.post(
    `/vehicle-pricing/get-hourlyRates/${companyId}`, {hours: hour,
});
};
export const addNewReserwationAPI = (reservation, companyId, decision) => {
  return API.post(`/reservation/book/${companyId}/${decision}`, reservation);
};

export const applyCouponAPI = (couponData) => {
  return API.post(`/coupon/apply-coupon`, couponData);
};

export const addCardAPI = (cardData) => {
  return API.post(`/card/add-booking-card`, cardData);
};

export const addClientAPI = (clientData, companyId) => {
  return API.post(`/client/add-client-booking/${companyId}`, clientData);
};

export const getCompanyAPI = (companyId) => {
  return API.get(`/corporate-company/get-company-details-booking/${companyId}`);
}

export const getClientDetailsByPhoneAPI = (companyId, phoneNumber) => {
  return API.get(`/client/get-client-booking/${companyId}/${phoneNumber}`);
}
export const getClientDetailsByEmailAPI = (companyId, email) => {
  return API.get(`/client/get-client-bookingByEmail/${companyId}/${email}`);
}

export const getCompanyTermsAPI = (id) => {
  return API.get(`/terms/get-terms/${id}`);
}

export const updateCouponCountAPI = (couponData) => {
  return API.post(`/coupon/update-coupon-count`, couponData);
};

export const sendOTPAPI = (clientData) => {
  return API.post(`/message/send-otp`, clientData);
};
export const verifyOTPAPI = (otpData) => {
  return API.post(`/client/verify-otp`, otpData);
};
