import React from "react";
import {BrowserRouter as Router, Routes, Route,} from "react-router-dom";
import Home from './components/mainCard/mainCard'
import Background from "./components/formsCard/background"
import { Provider } from "react-redux";
import store from "./redux/store";

function App() {
  return (
    <Provider store={store}> {/* Wrap the Provider around your app */}
      <Router>
        <Routes>
           {/* <Route path="/" element={<Home />} /> */}
          <Route path="/company/:companyId" element={<Background />} />
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
