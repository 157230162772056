import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Bynamecard from "./bynameCard";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./choosevehicle.css";
import { getVehicles, selectVehicles,getVehiclesHourlyRate } from "../../redux/feature/vehicle/slice";


function Byname({goNext, formData, companyId}) {

    const dispatch = useDispatch();

    const vehicles = useSelector(selectVehicles);

    console.log("Vehicles Here:", vehicles);

    useEffect(() => {

        const mainZipCodes = formData?.pickupAddress?.zipCode;
        const baseRateZipCodes = formData?.dropoffAddress?.zipCode;

        const zipCodes = {
        mainZipCode : mainZipCodes,
        baseRateZipCode : baseRateZipCodes
        };

        // dispatch(getVehicles({ companyId, mainZipCode, baseRateZipCode }));
       // dispatch(getVehicles({ companyId, zipCodes }));
      
        if (formData?.tripType == "Hourly") {
            const hours = formData?.duration;
            console.log("Hoursssss",formData);
            dispatch(getVehiclesHourlyRate({ companyId, hours }));
        }
        else {
            // dispatch(getVehicles({ companyId, mainZipCode, baseRateZipCode }));
            dispatch(getVehicles({ companyId, zipCodes }));
        }
    }, [dispatch, formData]);

    const onNextClick = (vehicle) => {
        goNext(vehicle);
      };

    return(
        <>
        {/* <div className="row d-flex flex-wrap w-100">
        {[1,2,3,4,5,6].map((item,index)=>{
        return(

            <div className="col-12 mx-auto my-3" key={index}>
                <Bynamecard goNext={onNextClick} />
            </div>
            )
        })}
        </div> */}
        <div className="row d-flex flex-wrap w-100">
            {vehicles?.vehicles
                ?.filter((vehicle) => vehicle.vehicleStatus !== "inactive" && vehicle.category === "Fleet") // Filter out inactive vehicles
                .map((vehicle, index) => (
                    <div className="col-12 mx-auto my-3" key={index}>
                    <Bynamecard vehicle={vehicle} goNext={onNextClick} />
                    </div>
            ))}
        </div>
        </>
    )
    }
  
  export default Byname;