// import React, { useState } from 'react';
// import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';


// const CheckoutForm = ({ onToken, ConnAccId }) => {
//   const stripe = useStripe();
//   const elements = useElements();
//   const [error, setError] = useState(null);
//   const [processing, setProcessing] = useState(false);

//   const handleSubmit = async (event) => {
//     event.preventDefault();

//     if (!stripe || !elements) {
//       return;
//     }

//     setProcessing(true);

//     const cardElement = elements.getElement(CardElement);
//     const { error, token } = await stripe.createToken(cardElement);

//     if (error) {
//       setError(error.message);
//       setProcessing(false);
//     } else {
//       setError(null);
//       onToken(token);
//       setProcessing(false);
//     }
//   };

//   return (
//     <form onSubmit={handleSubmit}>
//       <CardElement
//         className="form-control card-input"
//       />
//       {error && <div className="error">{error}</div>}
//       <button className="btn btn-primary mt-2" type="submit" disabled={!stripe || processing}>
//         {processing ? 'Adding...' : 'Add'}
//       </button>
//     </form>
//   );
// };

// export default CheckoutForm;


import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

const CheckoutForm = ({ setCard, ConnAccId }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  console.log("Connected Account Id Here:", ConnAccId);
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setProcessing(true);

    const cardElement = elements.getElement(CardElement);
    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    }, {
      stripeAccount: ConnAccId,
    });

    if (result.error) {
      setError(result.error.message);
      setProcessing(false);
    } else {
      setError(null);
      setCard(result.paymentMethod);
      setProcessing(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement className="form-control card-input" />
      {error && <div className="error">{error}</div>}
      <button className="btn btn-primary mt-5" type="submit" disabled={!stripe || processing}  style={{
    width: '150px', // Adjust width as needed
    backgroundColor: 'rgb(5, 140, 189)', // Set button color
    borderColor: 'rgb(5, 140, 189)', // Ensure border matches button color
    display: 'block', // Make the button a block element
    marginLeft: 'auto', // Align to the right
  }}>
        {processing ? 'Adding...' : 'Add'}
      </button>
    </form>
  );
};

export default CheckoutForm;
