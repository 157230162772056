// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAo-OIKz_THGwa-wzIButSbY5Z4HPiPb6U",
  authDomain: "limo-booking-9f0a4.firebaseapp.com",
  projectId: "limo-booking-9f0a4",
  storageBucket: "limo-booking-9f0a4.appspot.com",
  messagingSenderId: "238052971085",
  appId: "1:238052971085:web:3d19384f7908ab23f18204",
  measurementId: "G-1PEYP0Q293"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);