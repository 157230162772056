import React from "react";
import "./choosevehicle.css";
import { Icon } from "@iconify/react";
import { useDispatch } from "react-redux";
import {
  updateReservation,
  updateReservation2,
} from "../../redux/feature/reservation/slice";
import { Carousel } from "antd"; // Ant Design Carousel

function Vehiclecard({ vehicle, goNext }) {
  const dispatch = useDispatch();

  const handleChange = (field, value) => {
    dispatch(updateReservation({ field, value }));
  };

  const handleChange2 = (field, value) => {
    dispatch(updateReservation2({ field, value }));
  };

  const onNextClick = () => {
    if (vehicle?.basePrice === 0) {
      handleChange("isQuote", true);
      handleChange2("isQuote", true);
    }
    goNext(vehicle);
  };

  return (
    <div className="container-square elevate-hover">
      <div className="square-container-1 mt-2">
        {/* <div className="badge mt-1">{vehicle.vehicleTypeName}</div> */}
        <img src={vehicle.picture[0]} alt="Vehicle" className="img-fluid vehicle-img" />
              {/* Image Slider */}
        {/* <Carousel autoplay arrows>
          {vehicle.picture.map((image, index) => (
            <div key={index}>
              <img src={image} alt={`Vehicle ${index}`} className="img-fluid vehicle-img" />
            </div>
          ))}
        </Carousel> */}

        <div className="info-container">
          {/* <div className="mt-3 mb-4 d-flex flex-wrap align-items-center justify-content-between w-100">
            <h3 className="mb-0" style={{ fontSize: '20px' }}>{vehicle.vehicleTypeName}</h3>
          </div> */}
          <h3 className="mb-0 mt-1" style={{ fontSize: '24px', fontWeight: '700' }}>{vehicle.vehicleTypeName}</h3>

          {/* <div className="d-flex flex-wrap justify-content-start align-items-start col-12 mt-4 mb-1">
            <div className="col-10 mx-auto">
              <div className="row">
                <div className="col-4">
                  <Icon icon="mdi:seat-passenger" style={{ width: '30px', height: '30px' }}/>
                  <label className="ms-2" style={{fontSize: '18px'}}>{vehicle.passengerCapacity || 0}</label>
                </div>
                <div className="col-4">
                  <Icon icon="fluent:luggage-48-filled" style={{ width: '30px', height: '30px' }}/>
                  <label className="ms-2" style={{fontSize: '18px'}}>{vehicle.luggageCapacity || 0}</label>
                </div>
                <div className="col-4">
                  <Icon icon="mdi:car-seat" style={{ width: '30px', height: '30px' }}/>
                  <label className="ms-2" style={{fontSize: '18px'}}>{vehicle.seatCapacity || 0}</label>
                </div>
              </div>
            </div>
          </div> */}
<div className="d-flex flex-wrap justify-content-start align-items-start col-12 mt-4 mb-1">
  <div className="col-10 mx-auto">
    <div className="row">
      <div className="col-4" style={{ marginRight: '10px' }}> {/* Adds gap between columns */}
        <Icon icon="mdi:seat-passenger" style={{ width: '24px', height: '24px' }} /> {/* Reduced icon size */}
        <label className="ms-2" style={{ fontSize: '16px' }}>{vehicle.passengerCapacity || 0}</label>
      </div>
      <div className="col-4" style={{ marginRight: '10px' }}> {/* Adds gap between columns */}
        <Icon icon="fluent:luggage-48-filled" style={{ width: '24px', height: '24px' }} /> {/* Reduced icon size */}
        <label className="ms-2" style={{ fontSize: '16px' }}>{vehicle.luggageCapacity || 0}</label>
      </div>
      <div className="col-4">
        <Icon icon="mdi:car-seat" style={{ width: '24px', height: '24px' }} /> {/* Reduced icon size */}
        <label className="ms-2" style={{ fontSize: '16px' }}>{vehicle.seatCapacity || 0}</label>
      </div>
    </div>
  </div>
</div>
          <div className="horizontal-line mt-1"></div>

          <div className="mt-3 d-flex flex-wrap justify-content-between align-items-center w-100">
            <div className="col-md-6">
              <div className="old-price" style={{ cursor: 'pointer' }}>
                {vehicle.basePrice === 0 ? 'Get a Quote' : `$${vehicle.basePrice}`}
              </div>
            </div>
            <div className="col-md-6 d-flex justify-content-end">
              <button type="button" onClick={onNextClick} className="choose-vehicle justify-content-start">Select Vehicle</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Vehiclecard;


