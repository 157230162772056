import {
    applyCouponAPI,
    updateCouponCountAPI,
  } from "../../../utils/api.js";
import { errorAlert, successAlert } from "../../../utils/helper.js";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const handleApiError = (error) =>
    error.response?.data || error.message || "An unexpected error occurred";


export const applyCoupon = createAsyncThunk(
    "coupon/apply-coupon", 
    async (couponData, { rejectWithValue }) => {
    try {
      const response = await applyCouponAPI(couponData);
      return response.data;
    } catch (error) {
        return rejectWithValue(handleApiError(error));
    }
});

export const updateCouponCount = createAsyncThunk(
  "coupon/update-coupon-count", 
  async (couponData, { rejectWithValue }) => {
  try {
    const response = await updateCouponCountAPI(couponData);
    return response.data;
  } catch (error) {
      return rejectWithValue(handleApiError(error));
  }
});

const initialState = {
    coupons: [],
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
    saving:false,
};

const couponSlice = createSlice({
    name: 'coupon',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
      builder

      .addCase(applyCoupon.pending, (state, action) => {
        state.error = null;
        state.saving=true;
        console.log("State",state.saving);
      })
      .addCase(applyCoupon.fulfilled, (state, action) => {
        state.coupons.push(action.payload);
        state.error = null;
        state.saving=false;
        successAlert("Coupon Has Been Applied.")
      })
      .addCase(applyCoupon.rejected, (state, action) => {
        state.error = action.payload.message || 'Could Not Apply Coupon.';
        state.saving=false;
        errorAlert(action.payload.message || 'Could Not Apply Coupon.')
      })
      
      
      .addCase(updateCouponCount.pending, (state, action) => {
        state.error = null;
        state.saving=true;
        console.log("State",state.saving);
      })
      .addCase(updateCouponCount.fulfilled, (state, action) => {
        state.error = null;
        state.saving=false;
      })
      .addCase(updateCouponCount.rejected, (state, action) => {
        state.error = action.payload.message || 'Could Not Apply Coupon.';
        state.saving=false;
      });


    },
});

export const selectCoupons = (state) => state.coupon.coupons;

export default couponSlice.reducer;