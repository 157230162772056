import {
    addNewReserwationAPI,
  } from "../../../utils/api.js";
import { errorAlert, successAlert } from "../../../utils/helper.js";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Swal from "sweetalert2";

var reservationInit = {
    submitOption: "saveAndEmail",
    chargeOption: "",
    DBA: null,
    inBoundLead: "",
    clientType: "Individuals",
    customer: null,
    isQuote: false,
    isTrip: false,
    trip_id: null,
    orderType: "",
    carSeat: "0",
    lauggage: "0",
    passenger: "0",
    isPassenger: true,
    isRoundTrip: false,
    isDropOff: false,
    roundTripPair:'',
    parentReservationId:'',
    tripType: "One Way",
    passenger_details: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
    },
    pickupDate: "",
    pickupTime: "",
    totalTripDuration: 0,
    pickupAddress: {
      address: "",
      isAirport: "",
      airportInfo: {
        flightTime: "",
        airline: "",
        flightNumber: "",
        arrivalAirport: "",
      },
    },
    dropoffAddress: {
      address: "",
      isAirport: "",
      airportInfo: {
        flightTime: "",
        airline: "",
        flightNumber: "",
        arrivalAirport: "",
      },
    },
    stops: [],
    driver: null,
    Vehicle: null,
    isEarlyMorning: false,
    isMeetandGreet: false,
    internalNotes: "",
    tripNotes: "",
    baseRate: {
      type: "flatRate",
      value: "",
      otherValues: {},
      isPercentage: false,
    },
    gratuity: {
      value: "",
      isPercentage: false,
    },
    tolls: {
      value: "",
    },
    waitTime: {
      value: "",
      isPercentage: false,
    },
    adminFee: {
      value: "",
      isPercentage: false,
    },
    meetAndGreet: {
      value: "",
      isPercentage: false,
    },
    earlyMorning: {
      value: "",
      isPercentage: false,
    },
    taxPrice: {
      value: "",
      isPercentage: true,
    },
    discountPrice: {
      value: "",
      isPercentage: false,
    },
    parkingPrice: {
      value: "",
    },
    carSeatPrice: {
      value: "",
    },
    total: "",
};

var roundtripInit = {
  submitOption: "saveAndEmail",
  chargeOption: "",
  DBA: null,
  inBoundLead: "",
  clientType: "Individuals",
  customer: null,
  isQuote: false,
  isTrip: false,
  trip_id: null,
  orderType: "",
  carSeat: "0",
  lauggage: "0",
  passenger: "0",
  isPassenger: true,
  isRoundTrip: false,
  isDropOff: false,
  roundTripPair:'',
  parentReservationId:'',
  tripType:'Round Trip',
  passenger_details: {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  },
  pickupDate: "",
  pickupTime: "",
  totalTripDuration: 0,
  pickupAddress: {
    address: "",
    isAirport: "",
    airportInfo: {
      flightTime: "",
      airline: "",
      flightNumber: "",
      arrivalAirport: "",
    },
  },
  dropoffAddress: {
    address: "",
    isAirport: "",
    airportInfo: {
      flightTime: "",
      airline: "",
      flightNumber: "",
      arrivalAirport: "",
    },
  },
  stops: [],
  driver: null,
  Vehicle: null,
  isEarlyMorning: false,
  isMeetandGreet: false,
  internalNotes: "",
  tripNotes: "",
  baseRate: {
    type: "flatRate",
    value: "",
    otherValues: {},
    isPercentage: false,
  },
  gratuity: {
    value: "",
    isPercentage: false,
  },
  tolls: {
    value: "",
  },
  waitTime: {
    value: "",
    isPercentage: false,
  },
  adminFee: {
    value: "",
    isPercentage: false,
  },
  meetAndGreet: {
    value: "",
    isPercentage: false,
  },
  earlyMorning: {
    value: "",
    isPercentage: false,
  },
  taxPrice: {
    value: "",
    isPercentage: true,
  },
  discountPrice: {
    value: "",
    isPercentage: false,
  },
  parkingPrice: {
    value: "",
  },
  carSeatPrice: {
    value: "",
  },
  total: "",
};

const initialState = {
  loading: false,
  saving: false,
  status: null,
  error: null,
  validationError: null,
  validationError2: null,
  selectTollLoading:false,
  value: null,
  contactNames: [],
  reservations: [],
  cusCompletedRes: [],
  reservation: reservationInit,
  roundtrip: roundtripInit
};

const validateReservationData = (reservationData) => {
    const errors = {};
  
    // if (!reservationData.DBA) {
    //   errors.dba = "DBA is required";
    // }
  
    // if (!reservationData.customer) {
    //   errors.customer = "Customer is required";
    // }
    console.log("Errorss", reservationData.isPassenger);
    if (!reservationData.isPassenger) {
      if (!reservationData.passenger_details.firstName) {
        errors.firstName = "First name is required";
      }
  
      if (!reservationData.passenger_details.lastName) {
        errors.lastName = "Last name is required";
      }
      if (!reservationData.passenger_details.email) {
        errors.email = "Email is required";
      } else if (!isValidEmail(reservationData.passenger_details.email)) {
        errors.email = "Invalid email format";
      }
  
      if (!reservationData.passenger_details.phone) {
        errors.phone = "Phone number is required";
      } else if (!isValidPhone(reservationData.passenger_details.phone)) {
        errors.phone = "Invalid phone number format";
      }
    }
  
    if (!reservationData.pickupDate) {
      errors.pickupDate = "Pickup date is required";
    }
  
    if (!reservationData.pickupTime) {
      errors.pickupTime = "Pickup time is required";
    }
    if (!reservationData.pickupAddress.address) {
      errors.pickupAddress = "Pickup address is required";
    }
    if (!reservationData.dropoffAddress.address) {
      errors.dropoffAddress = "Dropoff address is required";
    }
    if (!reservationData.orderType) {
      errors.orderType = "Order type is required";
    }
    // if (
    //   reservationData.orderType === "Airport Pickup" ||
    //   reservationData.orderType === "Airport Dropoff"
    // ) {
    //   const addressInfo =
    //     reservationData.orderType === "Airport Dropoff"
    //       ? reservationData.dropoffAddress.airportInfo
    //       : reservationData.pickupAddress.airportInfo;
  
    //   const { flightTime, airline, flightNumber, arrivalAirport } = addressInfo;
  
    //   if (!flightTime) {
    //     errors.flightTime = "Flight time is required";
    //   }
  
    //   if (!airline) {
    //     errors.airline = "Airline is required";
    //   }
  
    //   // if (!flightNumber) {
    //   //   errors.flightNumber = "Flight number is required";
    //   // }
  
    //   // if (!arrivalAirport) {
    //   //   errors.arrivalAirport = "Arrival airport is required";
    //   // }
    // }
  
    // if (!reservationData.driver) {
    //   errors.driver = "Driver is required";
    // }
    if (!reservationData.Vehicle) {
      errors.Vehicle = "Vehicle is required";
    }
    if (
        !reservationData.baseRate ||
        isNaN(reservationData.baseRate.value) ||
        reservationData.baseRate.value <= 0 && reservationData.isQuote === false
    ) {
      errors.baseRate = "Base rate must be a number greater than zero";
    }
  
    return errors;
  };

  const validateInitialReservation = (reservationData) => {
    const errors = {};
  
    console.log("Errorss", reservationData.isPassenger);
    if (!reservationData.isPassenger) {
      if (!reservationData.passenger_details.firstName) {
        errors.firstName = "First name is required";
      }
  
      if (!reservationData.passenger_details.lastName) {
        errors.lastName = "Last name is required";
      }
      if (!reservationData.passenger_details.email) {
        errors.email = "Email is required";
      } else if (!isValidEmail(reservationData.passenger_details.email)) {
        errors.email = "Invalid email format";
      }
  
      if (!reservationData.passenger_details.phone) {
        errors.phone = "Phone number is required";
      } else if (!isValidPhone(reservationData.passenger_details.phone)) {
        errors.phone = "Invalid phone number format";
      }
    }
  
    if (!reservationData.pickupDate) {
      errors.pickupDate = "Pickup date is required";
    }
  
    if (!reservationData.pickupTime) {
      errors.pickupTime = "Pickup time is required";
    }
    if (!reservationData.pickupAddress.address) {
      errors.pickupAddress = "Pickup address is required";
    }
    if (!reservationData.dropoffAddress.address) {
      errors.dropoffAddress = "Dropoff address is required";
    }
    if (!reservationData.orderType) {
      errors.orderType = "Order type is required";
    }
    if (reservationData.passenger <=0) {
      errors.passenger = "Passenger Should Be More Than 0";
    }
    
  
    return errors;
  };

  const validateInitialRoundTrip = (roundtrip) => {
    const errors = {};
  
    console.log("Errorss", roundtrip.isPassenger);
    if (!roundtrip.isPassenger) {
      if (!roundtrip.passenger_details.firstName) {
        errors.firstName = "First name is required";
      }
  
      if (!roundtrip.passenger_details.lastName) {
        errors.lastName = "Last name is required";
      }
      if (!roundtrip.passenger_details.email) {
        errors.email = "Email is required";
      } else if (!isValidEmail(roundtrip.passenger_details.email)) {
        errors.email = "Invalid email format";
      }
  
      if (!roundtrip.passenger_details.phone) {
        errors.phone = "Phone number is required";
      } else if (!isValidPhone(roundtrip.passenger_details.phone)) {
        errors.phone = "Invalid phone number format";
      }
    }
  
    if (!roundtrip.pickupDate) {
      errors.pickupDate = "Pickup date is required";
    }
  
    if (!roundtrip.pickupTime) {
      errors.pickupTime = "Pickup time is required";
    }
    if (!roundtrip.pickupAddress.address) {
      errors.pickupAddress = "Pickup address is required";
    }
    if (!roundtrip.dropoffAddress.address) {
      errors.dropoffAddress = "Dropoff address is required";
    }
    // if (!roundtrip.orderType) {
    //   errors.orderType = "Order type is required";
    // }
    if (roundtrip.passenger <=0) {
      errors.passenger = "Passenger Should Be More Than 0";
    }
    
  
    return errors;
  };

  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };
  
  // Helper function to validate phone number format
  const isValidPhone = (phone) => {
    return /^\+?\d+(-\d+)*$/.test(phone);
  };

// export const addReservation = createAsyncThunk(
//     "reservation/addReservation",
//     async ({companyId}, { getState, rejectWithValue, dispatch }) => {
//       try {
//         dispatch(setValidationError({}));
//         // Access the reservation data from the Redux state
//         const reservationData = getState().reservation.reservation;

//         if (reservationData.submitOption != "draft") {
//           // Validate reservation data
//           const errors = validateReservationData(reservationData);

//           if (Object.keys(errors).length > 0) {
//             // If there are validation errors, dispatch setValidationError action
//             dispatch(setValidationError(errors));
//             return rejectWithValue(errors);
//           }
//         }

//         // Make API request to add a reservation
//         const response = await addNewReserwationAPI(reservationData, companyId);
//         return response;
//       } catch (error) {
//         return rejectWithValue(error);
//       }
//     }
// );
export const addReservation = createAsyncThunk(
  "reservation/addReservation",
  async ({companyId, decision}, { getState, rejectWithValue, dispatch }) => {
    try {
      dispatch(setValidationError({}));
      const { reservation, roundtrip } = getState().reservation;
      const reservationData = reservation;

      if (reservationData.submitOption !== "draft") {
        const errors = validateReservationData(reservationData);

        if (Object.keys(errors).length > 0) {
          dispatch(setValidationError(errors));
          return rejectWithValue(errors);
        }
      }

      let response;
      
      if (reservationData.tripType === "Round Trip") {
        response = await addNewReserwationAPI(reservationData, companyId, decision);
        console.log("Round Trip Response", response);
        if (response.data.isDropOff) {
          const updatedRoundtrip = {
            ...roundtrip,
            roundTripPair: response.data.trip._id,
            parentReservationId: response.data.trip?.parentReservationId,
          };
          await addNewReserwationAPI(updatedRoundtrip, companyId, decision);
        }
      } else {
        response = await addNewReserwationAPI(reservationData, companyId, decision);
      }

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

  const reservation = createSlice({
    name: "reservation",
    initialState,
    reducers: {
      updateReservation: (state, action) => {
        const { field, value } = action.payload;
        state.reservation = { ...state.reservation, [field]: value };
        console.log('Updated Reservation:', state.reservation);
      },
      updateReservation2: (state, action) => {
        const { field, value } = action.payload;
        state.roundtrip = { ...state.roundtrip, [field]: value };
        console.log('Updated Reservation Round Trip:', state.roundtrip);
      },
      resetReservation: (state, action) => {
        state.reservation = reservationInit;
      },
      setValidationError: (state, action) => {
        state.validationError = action.payload;
      },
      setValidationError2: (state, action) => {
        state.validationError2 = action.payload;
      },
      clearError: (state) => {
        state.error = null;
        state.validationError = null;
      },
      setReservation: (state, action) => {
        state.reservation = action.payload;
      },
    },
    extraReducers: (builder) => {
      builder

      .addCase(addReservation.pending, (state) => {
        state.saving = true;
        state.error = null;
      })
      .addCase(addReservation.fulfilled, (state, action) => {
        state.saving = false;
        state.status = action?.payload?.status;
        console.log('Saved Reservation Action',action);
        // if(action.payload.data?.isDropOff){
        //   let tempPickAdd=state.reservation.pickupAddress;
        //   state.reservation.pickupAddress = state.reservation.dropoffAddress;
        // state.reservation.dropoffAddress = tempPickAdd;
        // state.reservation.isRoundTrip=false;
        // state.reservation.roundTripPair=action.payload.data.trip?._id;
        // state.reservation.isDropOff=true;
        // state.reservation.parentReservationId=action.payload.data.trip?.parentReservationId;
        // }
        // else{
        //   state.reservation = reservationInit;
        // }
        Swal.fire({
          icon: "success",
          title: "Done",
          text: action.payload.data.isDropOff?"Your round trip has been saved.":"Your reservation has been successfully saved.",
        });
      })
      .addCase(addReservation.rejected, (state, action) => {
        state.saving = false;
        state.error = action.payload || "An error occurred";
        console.log("validation", action.payload);
        if (!action.payload.status) {
          Swal.fire({
            icon: "error",
            title: "Oops enter all inputs...",
            confirmButtonColor: "#3085d6",
            html:action.payload?.response?.data?.message?`<p>${action.payload?.response?.data?.message}</p>`: `<ul style="text-align: left; color: red; list-style-type: disc;">${Object.values(
                action.payload
            )
                .map((error) => `<li>${error}</li>`)
                .join("")}</ul>`,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: state.error,
          });
        }
      });

    },
});

export { validateReservationData, validateInitialReservation, validateInitialRoundTrip };
export const { setReservation, setValidationError, setValidationError2 } =
    reservation.actions;
export const selectReservationLoading = (state) => state.reservation.loading;
export const selectReservationStatus = (state) => state.reservation.status;
export const selectReservationSaving = (state) => state.reservation.saving;
export const selectReservationError = (state) => state.reservation.error;
export const selectReservation = (state) => state.reservation.reservation;
export const selectRoundTrip = (state) => state.reservation.roundtrip;
export const selectReservationValidationErrors = (state) =>
    state.reservation.validationError;
export const selectRoundTripValidationErrors = (state) =>
  state.reservation.validationError2;
export const { updateReservation, updateReservation2, clearError } =
    reservation.actions;
export default reservation.reducer;