import { configureStore } from "@reduxjs/toolkit";
import vehicleReducer from "./feature/vehicle/slice";
import reservationReducer from "./feature/reservation/slice";
import couponReducer from "./feature/coupon/slice";
import cardReducer from "./feature/card/slice";
import clientReducer from "./feature/client/slice";
import companyReducer from "./feature/company/slice";

export const store = configureStore({
    reducer: {
      vehicle: vehicleReducer,
      reservation: reservationReducer,
      coupon: couponReducer,
      card: cardReducer,
      client: clientReducer,
      company: companyReducer,
    },
  });

export default store;