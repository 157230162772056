import {
    getCompanyAPI,
    getCompanyTermsAPI,
  } from "../../../utils/api.js";
import { errorAlert, successAlert } from "../../../utils/helper.js";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const handleApiError = (error) =>
    error.response?.data || error.message || "An unexpected error occurred";



export const getCompany = createAsyncThunk(
    "corporate-company/get-company-details-booking", 
    async (companyId, { rejectWithValue }) => {
    try {
      const response = await getCompanyAPI(companyId);
      return response.data;
    } catch (error) {
        return rejectWithValue(handleApiError(error));
    }
});

export const getCompanyTerms = createAsyncThunk(
  "/terms/get-terms", 
  async (id, { rejectWithValue }) => {
  try {
    const response = await getCompanyTermsAPI(id);
    return response;
  } catch (error) {
      return rejectWithValue(handleApiError(error));
  }
});


const initialState = {
    companies: [],
    terms: [],
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
    saving:false,
};

const companySlice = createSlice({
    name: 'company',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
      builder

      .addCase(getCompany.pending, (state) => {
        state.status = 'loading';
        state.companies = null;
      })
      .addCase(getCompany.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.companies = action.payload.company;
        state.error = null; 
      })
      .addCase(getCompany.rejected, (state, action) => {
        state.status = 'failed';
        state.companies = null;
        state.error = action.payload.message || 'Could not get company data.';
      })

      .addCase(getCompanyTerms.pending, (state) => {
        state.status = 'loading';
        state.terms = null;
      })
      .addCase(getCompanyTerms.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.terms = action.payload.data;
        state.error = null; 
      })
      .addCase(getCompanyTerms.rejected, (state, action) => {
        state.status = 'failed';
        state.terms = null;
        state.error = action.payload.message || 'Could not get company terms.';
      });


    },
});

export const selectCompany = (state) => state.company.companies;
export const selectTerms = (state) => state.company.terms;

export default companySlice.reducer;