import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Input, Button, message, Segmented   } from "antd";
import { InputOTP } from "antd-input-otp";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { auth } from "../../../firebase.config";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import Cookies from "js-cookie";
import { successAlert, errorAlert } from "../../../utils/helper";
import {
  getClientDetailsByPhone,
  getClientDetailsByEmail,
  selectClient,
  sendOTP,
  verifyOTP
} from "../../../redux/feature/client/slice";

function SignIn({ onClose, companyId }) {
  const dispatch = useDispatch();
  const [usePhone, setUsePhone] = useState(false); // Track login method (phone or email)
  const [phoneNumber, setPhoneNumber] = useState("");
  const [showOTP, setShowOTP] = useState(false);
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  let recaptchaVerifier;

  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePhoneNumberChange = (value) => setPhoneNumber(value);

  const handleEnterClick = () => {
    if (usePhone) {
      if (phoneNumber.replace(/\D/g, "").length >= 10) {
        onSignup();
      } else {
        message.error("Please enter a valid phone number");
      }
    } else {
      // Handle email login (you might want to add a separate verification here)
      if (email) {
        onEmailSignup();
      } else {
        message.error("Please enter a valid email address");
      }
    }
  };

  const toggleLoginMethod = () => {
    setUsePhone(!usePhone);
    setShowOTP(false);
    setPhoneNumber("");
    setEmail("");
  };

  const handleOTPChange = (otp) => {
    setOtp(otp);
  };

  const handleCancelClick = () => {
    setShowOTP(false);
    setPhoneNumber("");
    setEmail("");
    setOtp("");
  };

  const  onSignup = async () => {
    setLoading(true);
    const clientData = {
      companyId: companyId,
      contact: phoneNumber,
      isEmail: false
    };
    console.log(" Clientt DaTA ",clientData);
    await dispatch(sendOTP(clientData));
    setLoading(false)
    setShowOTP(true);
 message.success("OTP sent successfully!");
    // const formatPh = `+${phoneNumber}`;

    // signInWithPhoneNumber(auth, formatPh, recaptchaVerifier)
    //   .then((confirmationResult) => {
    //     window.confirmationResult = confirmationResult;
    //     setLoading(false);
    //     setShowOTP(true);
    //     message.success("OTP sent successfully!");
    //   })
    //   .catch((error) => {
    //     console.error("Error sending OTP:", error);
    //     setLoading(false);
    //     message.error("Failed to send OTP. Please try again.");
    //   });
  };

  const onOTPVerify = async () => {
    setLoading(true);
    const otpString = otp.join("");
    console.log("OTP IS: ", otpString);

    try {
      // const result = await window.confirmationResult.confirm(otpString);
      // const user = result.user;
      //const token = await user.getIdToken();
      const otpData = {
        contact: phoneNumber ? phoneNumber : email,
        otp: otpString,
        companyId: companyId
      };
      await dispatch(verifyOTP(otpData));

      console.log("OTP Verified:");
      message.success("OTP verified successfully!");
      setLoading(false);

      // const client = await dispatch(getClientDetailsByPhone({
      //   companyId,
      //   phoneNumber,
      // }));
 // Check if contact is email or phone number
 const isEmail = otpData.contact.includes('@');

 // Call the appropriate dispatch based on the contact type
 const client = isEmail
   ? await dispatch(getClientDetailsByEmail({ companyId, email: otpData.contact }))
   : await dispatch(getClientDetailsByPhone({ companyId, phoneNumber: otpData.contact }));

      if (client?.payload?.data?.phoneNumber === phoneNumber ||client?.payload?.data?.email ===email) {
        const expires = new Date();
        expires.setDate(expires.getDate() + 1);
        // Cookies.set("authToken", token, { expires });
        Cookies.set("phoneNumber", phoneNumber, { expires });

        successAlert("User has been logged in.");
        setPhoneNumber("");
        setShowOTP(false);
        onClose();
      } else {
        console.error("Phone number mismatch");
        errorAlert("User Not Found.");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      message.error("Invalid OTP. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const onEmailSignup = async() => {
    setLoading(true);

    const clientData = {
      companyId: companyId,
      contact: email,
      isEmail: true
    };
   
    await dispatch(sendOTP(clientData));
    setLoading(false)
    setShowOTP(true);
 message.success("OTP sent successfully!");
    // You can implement email verification logic or send OTP here as well
    // For now, let's assume we send a verification email to the user
    // dispatch(getClientDetailsByEmail({ companyId, email }))
    //   .then((response) => {
    //     if (response?.payload?.data?.email === email) {
    //       message.success("Email login not implemented yet.");
    //       // You can send an OTP or handle email verification
    //       setLoading(false);
    //       onClose();
    //     } else {
    //       message.error("User not found.");
    //       setLoading(false);
    //     }
    //   })
    //   .catch((error) => {
    //     message.error("Error during email verification.");
    //     setLoading(false);
    //   });
  };

  return (
<div>
  <div id="recaptcha-container"></div>
  
  {/* Toggle between Phone and Email Login */}
  {/* <div style={{ marginBottom: "10px" }}>
    <Segmented
      options={["Phone Login", "Email Login"]}
      value={usePhone ? "Phone Login" : "Email Login"}
      onChange={(value) => toggleLoginMethod(value === "Phone Login")}
    />
  </div> */}
 <div style={{ display: "flex", justifyContent: "center", marginBottom: "20px" ,marginTop: "50px"}}>
   {/* Inline CSS */}
   <style>
        {`
          :where(.css-dev-only-do-not-override-j9bb5n).ant-segmented .ant-segmented-item-label {
            padding-bottom: 5px;
                padding: 0 85px 10px;
            margin-top: 5px;
          }
          :where(.css-dev-only-do-not-override-j9bb5n).ant-segmented .ant-segmented-item-selected {
             background-color: var(--btn-bg-color) !important;  /* Set dynamic background color */
  color: var(--btn-text-color) !important;  /* Set dynamic text color */
          }
             .ant-modal-title {
  text-align: center; /* Center-align the text */
  font-size: 35px !important;    /* Set font size to match h2 */
  font-weight: bold;  /* Optional: Make it bold like h2 */
  margin: 0;          /* Optional: Remove default margins if any */
}

        `}
      </style>
      <Segmented
        // options={["Phone Login", "Email Login"]}
        options={["Email Login"]}
        // value={usePhone ? "Phone Login" : "Email Login"}
        value={ "Email Login"}
        // onChange={(value) => toggleLoginMethod(value === "Phone Login")}
        onChange={(value) => toggleLoginMethod(value === "Email Login")}
        style={{
          fontSize: "28px", // Increase font size
          height: "45px",   // Increase overall height
          width: "290px",
         marginBottom: "30px"
        }}
        optionStyle={{
          color: "--btn-bg-color", // Active segment text color
        }}
      />
    </div>
  {!showOTP ? (
    <div className="mb-2 mt-4">
      {usePhone ? (
        <>
          {/* <label htmlFor="phoneNumber" style={{fontSize:"12pt"}}>Phone Number</label>
          <PhoneInput
            name="phoneNumber"
            id="phoneNumber"
            placeholder="Enter Phone Number"
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            country={"us"}
            inputProps={{ required: true }}
            inputStyle={{
              width: "100%",
              height: "43px",
              borderRadius: "4px",
              border: "1px solid #ccc",
            }}
            className="mb-3 mt-1"
          /> */}
        </>
      ) : (
        <>
          <label htmlFor="email" style={{fontSize:"16pt"}}><strong>Email</strong></label>
          <Input
            type="email"
            name="email"
            id="email"
            placeholder="Enter Email"
            value={email}
            onChange={handleEmailChange}
            required
            style={{
              width: "100%",
              height: "60px",
              borderRadius: "4px",
              fontSize: "14pt",
              border: "1px solid #ccc",
            }}
            className="mb-3 mt-1"
          />
        </>
      )}
      {/* <Button
        type="primary"
        onClick={handleEnterClick}
        style={{ marginRight: "10px", height: "40px", fontSize: "16px", width: "50%",justifyContent: "center" , backgroundColor:  "rgb(5, 140, 189)"}}
        loading={loading}
      >
        Send Code
      </Button> */}
      <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
  <Button
    type="primary"
    onClick={handleEnterClick}
    style={{
      marginRight: '10px',
      height: '40px',
      fontSize: '16px',
      width: '50%',
      justifyContent: 'center',
      backgroundColor: 'rgb(5, 140, 189)',
      marginTop: "50px"
    }}
    loading={loading}
  >
    Send Code
  </Button>
</div>

      {/* <Button onClick={handleCancelClick} style={{ height: "50px", fontSize: "16px" }}>
        Cancel
      </Button> */}
    </div>
  ) : (
    <div>
      <label htmlFor="otp">OTP</label>
      <InputOTP
        value={otp}
        onChange={handleOTPChange}
        autoFocus
        isInputNum
        numInputs={6}
        separator={<span>-</span>}
        inputStyle={{
          marginRight: "5px",
          marginBottom: "20px",
          marginTop: "20px",
        }}
      />
      <Button
        type="primary"
        onClick={onOTPVerify}
        style={{ marginRight: "10px", height: "50px", fontSize: "16px", width: "100px", marginTop: "50px" }}
        loading={loading}
      >
        Enter
      </Button>
      <Button onClick={handleCancelClick} style={{ height: "50px", fontSize: "16px",marginTop: "50px" }}>
        Cancel
      </Button>
    </div>
  )}
</div>
    // <div>
    //   <div id="recaptcha-container"></div>
    //   <Button type="link" onClick={toggleLoginMethod} style={{ marginBottom: "10px" }}>
    //     {usePhone ? "Login with Email" : "Login with Phone"}
    //   </Button>

    //   {!showOTP ? (
    //     <div className="mb-2 mt-4">
    //       {usePhone ? (
    //         <>
    //           <label htmlFor="phoneNumber">Phone Number</label>
    //           <PhoneInput
    //             name="phoneNumber"
    //             id="phoneNumber"
    //             placeholder="Enter Phone Number"
    //             value={phoneNumber}
    //             onChange={handlePhoneNumberChange}
    //             country={"us"}
    //             inputProps={{ required: true }}
    //             inputStyle={{
    //               width: "100%",
    //               height: "43px",
    //               borderRadius: "4px",
    //               border: "1px solid #ccc",
    //             }}
    //             className="mb-3 mt-1"
    //           />
    //         </>
    //       ) : (
    //         <>
    //           <label htmlFor="email">Email</label>
    //           <Input
    //             type="email"
    //             name="email"
    //             id="email"
    //             placeholder="Enter Email"
    //             value={email}
    //             onChange={handleEmailChange}
    //             required
    //             style={{
    //               width: "100%",
    //               height: "43px",
    //               borderRadius: "4px",
    //               border: "1px solid #ccc",
    //             }}
    //             className="mb-3 mt-1"
    //           />
    //         </>
    //       )}
    //       <Button
    //         type="primary"
    //         onClick={handleEnterClick}
    //         style={{ marginRight: "10px", height: "50px", fontSize: "16px", width: "100px" }}
    //         loading={loading}
    //       >
    //         Enter
    //       </Button>
    //       <Button onClick={handleCancelClick} style={{ height: "50px", fontSize: "16px" }}>
    //         Cancel
    //       </Button>
    //     </div>
    //   ) : (
    //     <div>
    //       <label htmlFor="otp">OTP</label>
    //       <InputOTP
    //         value={otp}
    //         onChange={handleOTPChange}
    //         autoFocus
    //         isInputNum
    //         numInputs={6}
    //         separator={<span>-</span>}
    //         inputStyle={{
    //           marginRight: "5px",
    //           marginBottom: "20px",
    //           marginTop: "20px",
    //         }}
    //       />
    //       <Button
    //         type="primary"
    //         onClick={onOTPVerify}
    //         style={{ marginRight: "10px", height: "50px", fontSize: "16px", width: "100px" }}
    //         loading={loading}
    //       >
    //         Enter
    //       </Button>
    //       <Button onClick={handleCancelClick} style={{ height: "50px", fontSize: "16px" }}>
    //         Cancel
    //       </Button>
    //     </div>
    //   )}
    // </div>
  );
}

export default SignIn;

// import React, { useState } from "react";
// import {useDispatch, useSelector} from "react-redux";
// import { Input, Button, message } from "antd";
// import { InputOTP } from "antd-input-otp";
// import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/lib/style.css";
// import { auth } from "../../../firebase.config";
// import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
// import Cookies from "js-cookie";
// import { successAlert, errorAlert } from "../../../utils/helper";
// import {
//   getClientDetailsByPhone,
//   selectClient,
// } from "../../../redux/feature/client/slice";

// function SignIn({onClose, companyId}) {

//   const dispatch = useDispatch();
//   const [usePhone, setUsePhone] = useState(true); // Track login method (phone or email)
//   const [phoneNumber, setPhoneNumber] = useState("");
//   const [showOTP, setShowOTP] = useState(false);
//   const [otp, setOtp] = useState("");
//   const [loading, setLoading] = useState(false);
//   const [email, setEmail] = useState("");
//   let recaptchaVerifier;
//   const handleEmailChange = (e) => setEmail(e.target.value);
//   const handlePhoneNumberChange = (value) => {
//     setPhoneNumber(value);
//   };

//   // const handleEnterClick = () => {
//   //   if (phoneNumber.replace(/\D/g, '').length >= 10) {
//   //     onSignup();
//   //   } else {
//   //     message.error("Please enter a valid phone number");
//   //   }
//   // };
//   const handleEnterClick = () => {
//     if (usePhone) {
//       if (phoneNumber.replace(/\D/g, "").length >= 10) {
//         onSignup();
//       } else {
//         message.error("Please enter a valid phone number");
//       }
//     } else {
//       // Handle email login (you might want to add a separate verification here)
//       // Implement email verification logic here, if needed
//       message.success("Email login not implemented.");
//     }
//   };
//   const toggleLoginMethod = () => {
//     setUsePhone(!usePhone);
//     setShowOTP(false);
//     setPhoneNumber("");
//     setEmail("");
//   };

//   const handleOTPChange = (otp) => {
//     setOtp(otp);
//   };

//   const handleCancelClick = () => {
//     setShowOTP(false);
//     setPhoneNumber("");
//     setEmail("");
//     setOtp("");
//   };

//   const onSignup = () => {
//     setLoading(true);

//     if (!recaptchaVerifier) {
//       recaptchaVerifier = new RecaptchaVerifier(auth,"recaptcha-container", {
//         size: "invisible",
//         callback: () => {
//         },
//         "expired-callback": () => {
//         },
//       });

//       window.recaptchaVerifier = recaptchaVerifier;
//     }

//     const formatPh = `+${phoneNumber}`;

//     signInWithPhoneNumber(auth, formatPh, recaptchaVerifier)
//       .then((confirmationResult) => {
//         window.confirmationResult = confirmationResult;
//         setLoading(false);
//         setShowOTP(true);
//         message.success("OTP sent successfully!");
//       })
//       .catch((error) => {
//         console.error("Error sending OTP:", error);
//         setLoading(false);
//         message.error("Failed to send OTP. Please try again.");
//       });
//   };

//   const onOTPVerify = async () => {
//     setLoading(true);
//     const otpString = otp.join("");
//     console.log("OTP IS: ", otpString);

//     try {
//         const result = await window.confirmationResult.confirm(otpString);
//         const user = result.user;
//         const token = await user.getIdToken();

//         console.log("OTP Verified:", result);
//         message.success("OTP verified successfully!");
//         setLoading(false);

//         const client = await dispatch(getClientDetailsByPhone({ companyId, phoneNumber }));
//         console.log("Client", client?.payload?.data);

//         if (client?.payload?.data?.phoneNumber === phoneNumber) {
//             console.log("Success");
//             const expires = new Date();
//             expires.setDate(expires.getDate() + 1);
//             Cookies.set("authToken", token, { expires });
//             Cookies.set("phoneNumber", phoneNumber, { expires });

//             successAlert("User Has Been Logged In.");
//             setPhoneNumber("");
//             setShowOTP(false);
//             onClose();
//         } else {
//             console.error("Phone number mismatch");
//             errorAlert("User Not Found.");
//             // message.error("Phone number does not match. Please try again.");
//         }
//     } catch (error) {
//         console.error("Error verifying OTP:", error);
//         message.error("Invalid OTP. Please try again.");
//     } finally {
//         setLoading(false);
//     }
// };


//   return (
//     <div>
//     <div id="recaptcha-container"></div>
//     <Button type="link" onClick={toggleLoginMethod} style={{ marginBottom: "10px" }}>
//       {usePhone ? "Login with Email" : "Login with Phone"}
//     </Button>

//     {!showOTP ? (
//       <div className="mb-2 mt-4">
//         {usePhone ? (
//           <>
//             <label htmlFor="phoneNumber">Phone Number</label>
//             <PhoneInput
//               name="phoneNumber"
//               id="phoneNumber"
//               placeholder="Enter Phone Number"
//               value={phoneNumber}
//               onChange={handlePhoneNumberChange}
//               country={"us"}
//               inputProps={{ required: true }}
//               inputStyle={{
//                 width: "100%",
//                 height: "43px",
//                 borderRadius: "4px",
//                 border: "1px solid #ccc",
//               }}
//               className="mb-3 mt-1"
//             />
//           </>
//         ) : (
//           <>
//             <label htmlFor="email">Email</label>
//             <Input
//               type="email"
//               name="email"
//               id="email"
//               placeholder="Enter Email"
//               value={email}
//               onChange={handleEmailChange}
//               required
//               style={{
//                 width: "100%",
//                 height: "43px",
//                 borderRadius: "4px",
//                 border: "1px solid #ccc",
//               }}
//               className="mb-3 mt-1"
//             />
//           </>
//         )}
//         <Button
//           type="primary"
//           onClick={handleEnterClick}
//           style={{ marginRight: "10px", height: "50px", fontSize: "16px", width: "100px" }}
//           loading={loading}
//         >
//           Enter
//         </Button>
//         <Button onClick={handleCancelClick} style={{ height: "50px", fontSize: "16px" }}>
//           Cancel
//         </Button>
//       </div>
//     ) : (
//       <div>
//         <label htmlFor="otp">OTP</label>
//         <InputOTP
//           value={otp}
//           onChange={handleOTPChange}
//           autoFocus
//           isInputNum
//           numInputs={6}
//           separator={<span>-</span>}
//           inputStyle={{
//             marginRight: "5px",
//             marginBottom: "20px",
//             marginTop: "20px",
//           }}
//         />
//         <Button
//           type="primary"
//           onClick={onOTPVerify}
//           style={{ marginRight: "10px", height: "50px", fontSize: "16px", width: "100px" }}
//           loading={loading}
//         >
//           Enter
//         </Button>
//         <Button onClick={handleCancelClick} style={{ height: "50px", fontSize: "16px" }}>
//           Cancel
//         </Button>
//       </div>
//     )}
//   </div>
//   );
// }

// export default SignIn;