import {
    addCardAPI,
  } from "../../../utils/api.js";
import { errorAlert, successAlert } from "../../../utils/helper.js";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const handleApiError = (error) =>
    error.response?.data || error.message || "An unexpected error occurred";


export const addCard = createAsyncThunk(
    "card/add-booking-card", 
    async (cardData, { rejectWithValue }) => {
    try {
      const response = await addCardAPI(cardData);
      return response.data;
    } catch (error) {
        return rejectWithValue(handleApiError(error));
    }
});


const initialState = {
    cards: [],
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
    saving:false,
};

const cardSlice = createSlice({
    name: 'card',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
      builder

      .addCase(addCard.pending, (state, action) => {
        state.error = null;
        state.saving=true;
        console.log("State",state.saving);
      })
      .addCase(addCard.fulfilled, (state, action) => {
        state.cards.push(action.payload);
        state.error = null;
        state.saving=false;
        successAlert("Card Has Been Charged.")
      })
      .addCase(addCard.rejected, (state, action) => {
        state.error = action.payload.message || 'Could Not Charge Card.';
        state.saving=false;
        errorAlert(action.payload.message || 'Could Not Charge Card.')
      });


    },
});

export const selectCards = (state) => state.card.cards;

export default cardSlice.reducer;