import {
  addClientAPI,
  getClientDetailsByPhoneAPI,
  getClientDetailsByEmailAPI,
  sendOTPAPI,
  verifyOTPAPI
} from "../../../utils/api.js";
import { errorAlert, successAlert } from "../../../utils/helper.js";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const handleApiError = (error) =>
  error.response?.data || error.message || "An unexpected error occurred";


export const addClient = createAsyncThunk(
  "client/add-client-booking", 
  async ({cardData, companyId}, { rejectWithValue }) => {
  try {
    const response = await addClientAPI(cardData, companyId);
    return response.data;
  } catch (error) {
      return rejectWithValue(handleApiError(error));
  }
});

export const getClientDetailsByPhone = createAsyncThunk(
"client/get-client-booking", 
async ({companyId, phoneNumber}, { rejectWithValue }) => {
try {
  return await getClientDetailsByPhoneAPI(companyId, phoneNumber);
} catch (error) {
    return rejectWithValue(handleApiError(error));
}
});
export const getClientDetailsByEmail = createAsyncThunk(
"client/getClientDetailsByEmail",
async ({ companyId, email }, { rejectWithValue }) => {
 return await getClientDetailsByEmailAPI(companyId,email);
}
);
export const sendOTP = createAsyncThunk(
"client/sendOTP", // action type
async (clientData , { rejectWithValue }) => {
  try {
    const response = await sendOTPAPI(clientData);
    return response.data; // Assuming you want to return the data from the response
  } catch (error) {
    return rejectWithValue(error.response.data); // Handle error by rejecting with value
  }
}
);
export const verifyOTP = createAsyncThunk(
"client/verify-otp", // action type
async (otpData , { rejectWithValue }) => {
  try {
    const response = await verifyOTPAPI(otpData);
    return response.data; // Assuming you want to return the data from the response
  } catch (error) {
    return rejectWithValue(error.response.data); // Handle error by rejecting with value
  }
}
);
const initialState = {
  clients: [],
  getclient: [],
  status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  saving:false,
};

const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder

    .addCase(addClient.pending, (state, action) => {
      state.error = null;
      state.saving=true;
      console.log("State",state.saving);
    })
    .addCase(addClient.fulfilled, (state, action) => {
      state.clients.push(action.payload.clientId);
      state.error = null;
      state.saving=false;
      // successAlert("Card Has Been Charged.")
    })
    .addCase(addClient.rejected, (state, action) => {
      state.error = action.payload.message || 'Could Not Add Client.';
      state.saving=false;
      // errorAlert(action.payload.message || 'Could Not Charge Card.')
    })

    .addCase(getClientDetailsByPhone.pending, (state, action) => {
      state.status = 'loading';
      state.getclient = null;
    })
    .addCase(getClientDetailsByPhone.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.getclient = action.payload.data;
      state.error = null; 
    })
    .addCase(getClientDetailsByPhone.rejected, (state, action) => {
      state.status = 'failed';
      state.getclient = null;
      state.error = action.payload.message || 'Could not get client.';
    })
    .addCase(getClientDetailsByEmail.pending, (state) => {
      state.loading = true;
    })
    .addCase(getClientDetailsByEmail.fulfilled, (state, action) => {
      state.loading = false;
      state.getclient = action.payload.data;
      state.error = null;
    })
    .addCase(getClientDetailsByEmail.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    })
    .addCase(sendOTP.pending, (state) => {
      state.error = null;
      state.saving = true;
      console.log("State saving:", state.saving); // You can remove this after testing
    })
    .addCase(sendOTP.fulfilled, (state, action) => {
      state.otp = action.payload; // Assuming the OTP is in the payload
      state.error = null;
      state.saving = false;
      // successAlert("OTP Sent Successfully.");
    })
    .addCase(sendOTP.rejected, (state, action) => {
      state.error = action.payload.message || "Could Not Send OTP.";
      state.saving = false;
      // errorAlert(action.payload.message || "Error Sending OTP.");
    })
    
    .addCase(verifyOTP.pending, (state) => {
      state.error = null;
      state.saving = true;
      console.log("State saving:", state.saving); // You can remove this after testing
    })
    .addCase(verifyOTP.fulfilled, (state, action) => {
      state.otp = action.payload; // Assuming the OTP is in the payload
      state.error = null;
      state.saving = false;
      // successAlert("OTP Sent Successfully.");
    })
    .addCase(verifyOTP.rejected, (state, action) => {
      state.error = action.payload.message || "Could Not Send OTP.";
      state.saving = false;
      // errorAlert(action.payload.message || "Error Sending OTP.");
    });


  },
});

export const selectClients = (state) => state.client.clients;
export const selectClient = (state) => state.client.getclient;

export default clientSlice.reducer;