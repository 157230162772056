import React, { useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';

function Counter({ increment, decrement, text, onCounterChange }) {
    const [counter, setCounter] = useState(parseInt(text));

    const onIncrement = () => {
        const newCounter = counter + 1;
        setCounter(newCounter);
        onCounterChange(newCounter);
    };

    const onDecrement = () => {
        if (counter > 0) {
            const newCounter = counter - 1;
            setCounter(newCounter);
            onCounterChange(newCounter);
        }
    };

    const buttonStyle = {
        backgroundColor: '#058CBD',
        borderColor: '#058CBD',
        color: 'white',
    };

    return (
        // <div style={{width: '80%'}} className="counter d-flex align-items-center shadow-sm justify-content-between rounded overflow-hidden">
        //     <button
        //         type="button"
        //         onClick={onDecrement}
        //         className="counter-button btn px-3 py-2 rounded-start"
        //         style={buttonStyle}
        //     >
        //         -
        //     </button>
        //     <p className="counter-value bg-light flex-grow-1 text-center fs-4 m-0">{counter}</p>
        //     <button
        //         type="button"
        //         onClick={onIncrement}
        //         className="counter-button btn px-3 py-2 rounded-end"
        //         style={buttonStyle}
        //     >
        //         +
        //     </button>
        // </div>
        <div style={{width: '80%'}} className="counter d-flex align-items-center shadow-sm justify-content-between rounded overflow-hidden">
        <button
            type="button"
            onClick={onDecrement}
            className="counter-button btn px-3 py-2 rounded-start"
            style={buttonStyle}
        >
            -
        </button>
        <p className="counter-value bg-light flex-grow-1 text-center fs-4 m-0">{counter}</p>
        <button
            type="button"
            onClick={onIncrement}
            className="counter-button btn px-3 py-2 rounded-end"
            style={buttonStyle}
        >
            +
        </button>
    </div>
    );
}

export default Counter;
