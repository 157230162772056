import React, { useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';

const SimpleMap = ({ addressA, addressB ,height,width}) => {
  const [directionsResponse, setDirectionsResponse] = useState(null);
  const [mapApiLoaded, setMapApiLoaded] = useState(false);
  const [mapInstance, setMapInstance] = useState(null);
  const [mapsApi, setMapsApi] = useState(null);

  const defaultProps = {
    center: {
      lat: 40.7128,
      lng: -74.0060,
    },
    zoom: 11,
  };

  useEffect(() => {
    if (mapApiLoaded && addressA && addressB && mapsApi && mapInstance) {
      const directionsService = new mapsApi.DirectionsService();

      directionsService.route(
        {
          origin: addressA,
          destination: addressB,
          travelMode: mapsApi.TravelMode.DRIVING,
        },
        (response, status) => {
          if (status === mapsApi.DirectionsStatus.OK) {
            setDirectionsResponse(response);
          } else {
            console.error(`failed to fetch directions ${status}`);
          }
        },
      );
    }
  }, [addressA, addressB, mapApiLoaded, mapsApi, mapInstance]);

  const renderDirections = () => {
    if (mapInstance && mapsApi && directionsResponse) {
      const directionsRenderer = new mapsApi.DirectionsRenderer();
      directionsRenderer.setMap(mapInstance);
      directionsRenderer.setDirections(directionsResponse);
      directionsRenderer.setOptions({
        suppressMarkers: true, // Suppress the default markers
        polylineOptions: {
          strokeColor: '#ff6347',
          strokeWeight: 4,
        },
      });

      const leg = directionsResponse.routes[0].legs[0];
      new mapsApi.Marker({ position: leg.start_location, map: mapInstance, label: 'P' }); // Pickup marker
      new mapsApi.Marker({ position: leg.end_location, map: mapInstance, label: 'D' }); // Dropoff marker
    }
  };

  useEffect(() => {
    renderDirections();
  }, [directionsResponse, mapInstance, mapsApi]);

  return (
    <div style={{ height: height?height:'200px', width: width?width:'100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyBia7fF0j5nyIGAYq6c3sxk4a4bOpyL6Jw" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => {
          setMapInstance(map);
          setMapsApi(maps);
          setMapApiLoaded(true);
        }}
      />
    </div>
  );
};

export default SimpleMap;