import React, { useEffect, useState } from "react";
import "../formsCard/confirm.css";
import Car from "../img/car.svg";
import Car2 from "../img/car2.svg";
import Passenger from "../img/passenger.svg";
import Luggage from "../img/luggage.svg";
import Seat from "../img/seat.svg";
import Duration from "../img/duration.svg";
import {useDispatch, useSelector} from "react-redux";
import GoogleMap from "../formsCard/GoogleMap/GoogleMap";
import {
  selectReservation,
  selectRoundTrip,
} from "../../redux/feature/reservation/slice";
import { Icon } from "@iconify/react";
import { Checkbox, Modal } from "antd";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  updateReservation,
  updateReservation2,
  addReservation,
} from "../../redux/feature/reservation/slice";
import {
  applyCoupon,
  updateCouponCount,
} from "../../redux/feature/coupon/slice";
import {
  addClient,
  getClientDetailsByPhone,
  selectClient,
} from "../../redux/feature/client/slice";
import {
  getCompanyTerms,
  selectTerms,
} from "../../redux/feature/company/slice";
import CheckoutForm from "./Card/CheckForm";
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import Cookies from "js-cookie";
import Swal from 'sweetalert2';
import { Carousel } from "antd"; // Ant Design Carousel
function Confirm({vehicle, ConnAccId, CompanyId, CreditCard, DBA, URL, pricingFormat, payOption}) {

  const dispatch = useDispatch();
  const [coupon, setCoupon] = useState('');
  const [total, setTotal] = useState(0);
  const reservation = useSelector(selectReservation);
  const roundtrip = useSelector(selectRoundTrip);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [couponApplied, setCouponApplied] = useState(false);
  const [oldTotal, setOldTotal] = useState(total);
  const [usedCoupon, setUsedCoupon] = useState('');

  const authToken = Cookies.get("authToken");
  const phoneNumber = Cookies.get("phoneNumber");
  
  const client = useSelector(selectClient);
  const terms = useSelector(selectTerms);

  console.log("DBA:", DBA);

  useEffect(() =>{
    const companyId = CompanyId;
    if(phoneNumber)
      {
        dispatch(getClientDetailsByPhone({companyId, phoneNumber}))
      }
  }, []);

  useEffect(() =>{

    const id = CompanyId;
    dispatch(getCompanyTerms(id))

  }, []);

  console.log("Here are Terms: ", terms);

  const showModal = () => {
    setIsModalVisible(true);
  };

  // Close modal
  const handleClose = () => {
    setIsModalVisible(false);
  };

  const [clientDetails, setClientDetails] = useState({
    phone: '',
    email: '',
    firstName: '',
    lastName: '',
  });

  useEffect(() => {
    if (client) {
      setClientDetails({
        phone: client.phoneNumber || '',
        email: client.email || '',
        firstName: client.firstName || '',
        lastName: client.lastName || '',
      });
    }
  }, [client]);

  const [stripepromise, setStripePromise] = useState();
  const stripeConnAccount = ConnAccId;

  useEffect(() => {
      const promise = loadStripe('pk_test_51OpwTTB0PwSGyFJmGKMdLeZ7KHi98fSbm7jIvDEPLMIgFyokAZx5vdgcgVFFmJ67dwPHIXmJ8HlpoRiudr1P3OMn00qqvPIKh0', {
        stripeAccount: stripeConnAccount,
      });
      setStripePromise(promise);
  }, [stripeConnAccount]);

  const [individualCards, setIndividualCards] = useState([]);
  const [corporateCards, setCorporateCards] = useState([]);

  useEffect(() => {
    if (vehicle?.basePrice !== 0)
    {
      const basePrice = vehicle?.basePrice || 0;
      const gratuity = vehicle?.gratuity || 0;
      const tax = vehicle?.tax || 0;
      const carSeat = vehicle?.carSeat || 0;
      const waitingTime = vehicle?.waitingTime || 0;
      const adminFee = vehicle?.adminFee || 0;
      const toll = vehicle?.toll || 0;

      const totalAmount = basePrice + gratuity + tax + carSeat + waitingTime + adminFee + toll;
      setTotal(totalAmount);
    }
    
  }, []);

  const basePrice = vehicle?.basePrice || 0;
  const gratuity = vehicle?.gratuity || 0;
  const tax = vehicle?.tax || 0;
  const carSeat = vehicle?.carSeat || 0;
  const waitingTime = vehicle?.waitingTime || 0;
  const adminFee = vehicle?.adminFee || 0;
  const toll = vehicle?.toll || 0;
  // let total = basePrice + gratuity + tax + carSeat + waitingTime + adminFee;
  
  const mapFeatures = (features) => {
    return Object.entries(features).map(([key, value]) => ({
      heading: key.charAt(0).toUpperCase() + key.slice(1),
      items: Object.values(value).map((feature) => ({
        icon: feature.icon,
        text: feature.name,
        isAvailable: feature.isAvailable,
      })),
    }));
  };

  const details = mapFeatures(vehicle?.features || {});

  const handleChange = (field, value) => {
    dispatch(updateReservation({field, value}));
  };

  const handleChange2 = (field, value) => {
    dispatch(updateReservation2({field, value}));
  };

  const handleCouponChange = (event) => {
    setCoupon(event.target.value);
  };

  const handleApplyCouponChange = async () => {
    try {
      let couponTotal = total;

      if (reservation.tripType === "Round Trip") {
        couponTotal *= 2; // Double the total if it's a round trip
      }

      const couponData = {
        couponName: coupon,
        totalAmount: couponTotal,
        companyId: vehicle?.vehicleAssociateCompanyId
      };

      const response = await dispatch(applyCoupon(couponData));
      console.log("Coupon applied successfully:", response.payload.discount);

      if (response.payload.discount) {
        setOldTotal(total);
        setCouponApplied(true);
        setUsedCoupon(coupon);
        setCoupon("");
        if (reservation.tripType === "Round Trip") {
          setTotal(response.payload.discount / 2);
        } else {
          setTotal(response.payload.discount);
        }
      }
      

    } catch (error) {
      console.error("Error while saving:", error);
    }
  };

  const [stripeToken, setStripeToken] = useState(null);

  const handleToken = (token) => {
    console.log("Token Here: ", token);
    setStripeToken(token);
  };

  const handleSave = async (decision) => {

    console.log("Client Here:", client);

    if (CreditCard) {
      
      if (client && typeof client === 'object' && !Array.isArray(client)) {
        
        if ((!client.personalCards || client.personalCards.length === 0) &&
            (!client.corporateCards || client.corporateCards.length === 0) &&
            (!individualCards || individualCards.length === 0)) {
          Swal.fire({
            icon: 'error',
            title: 'Credit Card Required',
            text: 'Please provide credit card information before proceeding.',
          });
          return;
        }
      } 
      
      else if ((!client || (Array.isArray(client) && client.length === 0)) && 
               (!individualCards || individualCards.length === 0)) {
        Swal.fire({
          icon: 'error',
          title: 'Credit Card Required',
          text: 'Please provide credit card information before proceeding.',
        });
        return;
      }
    }
    
    if (vehicle?.basePrice !== 0)
      {
        handleChange("waitTime", {
          ...reservation.waitTime, 
          value: vehicle?.waitingTime,
          isPercentage: false,
        });
        handleChange("adminFee", {
          ...reservation.adminFee, 
          value: vehicle?.adminFee,
          isPercentage: false,
        });
        handleChange("baseRate", {
          ...reservation.baseRate, 
          value: vehicle?.basePrice,
          isPercentage: false,
        });
        handleChange("carSeatPrice", {
          ...reservation.carSeatPrice, 
          value: vehicle?.carSeat,
        });
        handleChange("gratuity", {
          ...reservation.gratuity, 
          value: vehicle?.gratuity,
          isPercentage: false,
        });
        handleChange("taxPrice", {
          ...reservation.taxPrice, 
          value: vehicle?.tax,
          isPercentage: true,
        });
      }

    handleChange("total", total);
    handleChange("Vehicle", vehicle._id);
    handleChange("DBA", DBA);

    //Round Trip
    if(reservation.tripType === "Round Trip")
      {
        // handleChange("isDropOff", true);
        handleChange("isRoundTrip", true);
        if (vehicle?.basePrice !== 0)
          {
            handleChange2("waitTime", {
              ...roundtrip.waitTime, 
              value: vehicle?.waitingTime,
              isPercentage: false,
            });
            handleChange2("adminFee", {
              ...roundtrip.adminFee, 
              value: vehicle?.adminFee,
              isPercentage: false,
            });
            handleChange2("baseRate", {
              ...roundtrip.baseRate, 
              value: vehicle?.basePrice,
              isPercentage: false,
            });
            handleChange2("carSeatPrice", {
              ...roundtrip.carSeatPrice, 
              value: vehicle?.carSeat,
            });
            handleChange2("gratuity", {
              ...roundtrip.gratuity, 
              value: vehicle?.gratuity,
              isPercentage: false,
            });
            handleChange2("taxPrice", {
              ...reservation.taxPrice, 
              value: vehicle?.tax,
              isPercentage: true,
            });
          }
        
        handleChange2("total", total);
        handleChange2("isDropOff", true);
        handleChange2("isRoundTrip", false);
        handleChange2("Vehicle", vehicle._id);
        handleChange2("DBA", DBA);
      }

    const companyId = vehicle.vehicleAssociateCompanyId;
    console.log("Passenger:", reservation?.passenger_details);
    console.log("Card Id:", individualCards);

    const passengerDetails = reservation?.passenger_details || {};

    const formDataToSend = new FormData();
    for (const key in passengerDetails) {
      formDataToSend.append(key, passengerDetails[key]);
    }
    formDataToSend.append("individualcards", JSON.stringify(individualCards));
    formDataToSend.append("corporatecards", JSON.stringify(corporateCards));
    const client2 = await dispatch(addClient({cardData: formDataToSend, companyId}));
    console.log("Client Details:", client2.payload.clientId);

    handleChange("customer", client2.payload.clientId);

    if (reservation.tripType === "Round Trip")
      {
        handleChange2("customer", client2.payload.clientId);
      }
    console.log("Decision:", decision);
    try {
      const response = await dispatch(addReservation({companyId, decision}));

      if (!response.error && couponApplied) {
        const couponData = {
          couponName: usedCoupon,
          companyId: vehicle?.vehicleAssociateCompanyId
        };
        await dispatch(updateCouponCount(couponData));
      }

      if (!response.error && URL) {
        console.log("Response:", response);
        window.location.href = URL;
      }
      else if (!response.error && !URL)
      {
        window.location.reload();
      }

    } 
    catch (error) {
    console.error("Error while saving:", error);
    }

  };

  return (
    <>
      <div className="d-flex col-md-12 ml-10px flex-wrap mt-5">
        <div className="col-lg-7">
          <div className="col-md-10 mx-auto">
            <div className="confirm-square">
              <div className="w-100 mb-2">
                <h3>Booking Details</h3>
              </div>
              {/* <div className="square-confirm-2 w-100 py-5"> */}
              <div className="square-confirm-2 w-100 py-3">
              <div className="d-flex justify-content-between mb-3">
                  {/* Left: Trip Type */}
                  <div className="d-flex flex-column align-items-start">
                    <span style={{ fontWeight: '600' }}>Trip Type</span>
                    <div className="px-2 py-2 mx-0 mt-3" style={{ backgroundColor: '#ECE7E1', borderRadius: '8px' }}>
                      <span>{reservation.tripType}</span>
                    </div>
                  </div>

                  {/* Right: Order Type */}
                  <div className="d-flex flex-column align-items-end">
                    <span style={{ fontWeight: '600' }}>Order Type</span>
                    <div className="px-3 py-2 mx-2 mt-3" style={{ backgroundColor: '#ECE7E1', borderRadius: '8px' }}>
                      <span>{reservation?.orderType}</span>
                    </div>
                  </div>
                </div>
                  {/* <div className="d-flex align-items-center mb-2" style={{marginTop: '-10px'}}>
                    <span style={{fontWeight: '600'}}>Trip Type: </span>
                    <div className="px-2 py-2 mx-2" style={{ backgroundColor: '#ECE7E1', borderRadius: '8px' }}> */}
                    {/* <span className="ms-2">{reservation.tripType}</span> */}
                    {/* <span >{reservation.tripType}</span>
                    </div>
                  </div>
                <div className="d-flex align-items-center justify-content-between mb-3">
                  
                  <div className="d-flex align-items-center">
                  <span style={{fontWeight: '600'}}>Order Type: </span>
                  <div className="px-3 py-2 mx-2" style={{ backgroundColor: '#ECE7E1', borderRadius: '8px' }}>
                    <span>{reservation?.orderType}</span>
                  </div> */}
                    {/* <img src={Car} alt="car" className="me-2" style={{ width: '35px', height: '35px' }} /> */}
                    {/* <span>{vehicle?.vehicleTypeName} - {vehicle?.name} : {vehicle?.color}</span> */}
                  {/* </div> */}

                 
                {/* </div> */}
                <div className="d-flex align-items-center ms-3" >
                  <img src={Car2} alt="car icon" className="me-2" style={{ width: '24px', height: '24px' }} />
                  <div className="ms-1">
                    <span>{reservation?.pickupAddress?.address}</span>
                    <div style={{color: '#61758A'}}>{reservation?.pickupTime}</div>
                  </div>
                </div>
                <div  style={{ borderLeft: '2px solid #058CBD', height: '60px', marginLeft: '1.7rem' }}></div>
                <div className="d-flex align-items-center ms-3" >
                  <img src={Car2} alt="car icon" className="me-2" style={{ width: '24px', height: '24px' }} />
                  <div className="ms-1">
                    <span>{reservation?.dropoffAddress?.address}</span>
                    {/* <div style={{color: '#61758A'}}>5:49PM</div> */}
                  </div>
                </div>
                {/* First Container */}
                <div className="d-flex mt-4 new-container-stats">
                  <div className="small-container me-3">
                    <div className="d-flex align-items-center justify-content-center mt-2 px-2">
                      <img src={Passenger} alt="passenger" className="me-2" style={{ width: '24px', height: '24px' }} />
                      <span>Passengers</span>
                    </div>
                    <div className="text-center  mt-1">
                      <div style={{fontSize: '29.22px', fontWeight: '600'}}>{reservation?.passenger}</div>
                    </div>
                  </div>
                  <div className="small-container me-3">
                    <div className="d-flex align-items-center justify-content-center mt-2 px-2">
                      <img src={Luggage} alt="luggage" className="me-2" style={{ width: '24px', height: '24px' }} />
                      <span>Luggage</span>
                    </div>
                    <div className="text-center mt-1">
                      <div style={{fontSize: '29.22px', fontWeight: '600'}}>{reservation?.lauggage}</div>
                    </div>
                  </div>
                  <div className="small-container me-3">
                    <div className="d-flex align-items-center justify-content-center mt-2 px-2">
                      <img src={Seat} alt="seat" className="me-2" style={{ width: '24px', height: '24px' }} />
                      <span>Car Seats</span>
                    </div>
                    <div className="text-center  mt-1">
                      <div style={{fontSize: '29.22px', fontWeight: '600'}}>{reservation?.carSeat}</div>
                    </div>
                  </div>
                  {reservation.tripType === "Hourly" && (
                    <div className="small-container me-3">
                      <div className="d-flex align-items-center justify-content-center mt-2 px-2">
                        <img src={Duration} alt="duration" className="me-2" style={{ width: '24px', height: '24px' }} />
                        <span>Duration</span>
                      </div>
                      <div className="text-center mt-1">
                        <div style={{fontSize: '29.22px', fontWeight: '600'}}>{(reservation?.totalTripDuration*60)/60}</div>
                        <div style={{fontSize: '9.74px', fontWeight: '600'}}>Hours</div>
                      </div>
                    </div>
                  )}
                </div>
                
              </div>
            </div>


            <div className="confirm-square">
              <div className="mb-2 mt-2 w-100">
                <h3>Vehicle</h3>
              </div>
              <div className="square-confirm-7 d-flex flex-column">
                <div className="d-flex w-100 upside">
                  <div className="left-side px-4">
                    {/* <img
                      src={vehicle.picture && vehicle.picture[0] 
                        ? vehicle.picture[0] 
                        : 'https://i.imgur.com/Witktvj.png'
                      }
                      alt="Car"
                      className="img-fluid img-custom"
                    /> */}
                      {/* Ant Design Carousel for Images */}
          {/* <Carousel autoplay arrows>
            {vehicle.picture && vehicle.picture.length > 0 ? (
              vehicle.picture.map((image, index) => (
                <div key={index}>
                  <img
                    src={image}
                    alt={`Car ${index}`}
                    className="img-fluid img-custom"
                  />
                </div>
              ))
            ) : (
              <div>
                <img
                  src="https://i.imgur.com/Witktvj.png"
                  alt="Fallback Car"
                  className="img-fluid img-custom"
                />
              </div>
            )}
          </Carousel> */}
<Carousel 
  autoplay 
  arrows 
  className="custom-carousel"
>
  {vehicle.picture && vehicle.picture.length > 0 ? (
    vehicle.picture.map((image, index) => (
      <div key={index}>
        <img
          src={image}
          alt={`Car ${index}`}
          className="img-fluid img-custom"
        />
      </div>
    ))
  ) : (
    <div>
      <img
        src="https://i.imgur.com/Witktvj.png"
        alt="Fallback Car"
        className="img-fluid img-custom"
      />
    </div>
  )}
</Carousel>


                  </div>
                  <div className="right-side d-flex flex-row w-100 px-1 mt-4">
                  {details.map((section, index) => (
                      <div key={index} className="mb-3 me-2">
                        <h5 style={{ fontSize: '14px' }}>{section.heading}</h5>
                        <div className="column">
                          {section.items.map((item, idx) => (
                            item.isAvailable && (
                              <div key={idx} className="d-flex align-items-center mb-2">
                                <Icon icon={item.icon} className="me-1" style={{ width: '16px', height: '16px' }} />
                                <span style={{ fontSize: '12px' }}>{item.text.substring(0, 8)}</span>
                              </div>
                            )
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="d-flex justify-content-start flex-column w-100 mt-3">
                  <h2 className="heading-1">{vehicle?.name}</h2>
                  <p className="mt-1" style={{fontSize: '14px', fontWeight: '200'}}>
                      {vehicle?.description ? vehicle.description : "No description found"}
                    </p>
                    {total !== 0 && (
                    <div className="old-price">${vehicle?.basePrice}</div>
                    )}
                </div>
              </div>
            </div>


            <div className="confirm-square">
              <div className="mb-2 mt-2 w-100">
                <h3>Payment</h3>
              </div>
              <div className="square-confirm-3">
                <div className="pricing-field">
                  <label htmlFor="cardholder-name">Cardholder Name</label>
                  <input type="text" id="cardholder-name" />
                  <br />
                  <label htmlFor="card-details">Card Details</label>
                  {/* <input type="text" id="card-details" /> */}
                  {stripepromise && (
                    <Elements stripe={stripepromise}>
                        <CheckoutForm 
                          onToken={handleToken} 
                          ConnAccId={stripeConnAccount} 
                          setCard={(newCard) => setIndividualCards((cards) => [...cards, newCard])}
                        />
                    </Elements>
                  )}
                  <br />
                </div>
              </div>
            </div>

            <div className="confirm-square">
              <div className="mb-2 mt-2 w-100">
                <h3>Notes</h3>
              </div>
              <div className="square-confirm-3">
                <div className="pricing-field">
                  <label htmlFor="notes">Notes</label>
                  {/* <input type="text" id="cardholder-name" /> */}
                  <textarea 
                    name="notes"
                    value={reservation.tripNotes}
                    onChange={(e) => {
                        handleChange("tripNotes", e.target.value);
                        if (reservation.tripType === "Round Trip") {
                          handleChange2("tripNotes", e.target.value);
                        }
                    }} 
                    style={{
                      padding: '8px',
                      border: '1px solid ',
                      borderColor: '#D0D5DD',
                      borderRadius: '4px'
                  }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-5">
          <div className="col-md-10 mx-auto">
            <div>
              <div className="confirm-square">
                <div className="w-100 mb-2">
                  <h3>Map</h3>
                </div>
                <div className="square-confirm-map">
                  {/* <img className="image-map" src={GPS} alt="GPS" /> */}
                  <GoogleMap
                      // width={"100%"}
                      height={"425px"}
                      addressA={reservation?.pickupAddress?.address}
                      addressB={reservation?.dropoffAddress?.address}
                  />
                </div>
              </div>
              {total !== 0 && (
                <div className="confirm-square">
                  <div className="w-100 mb-2 mt-2">
                    <h3>Price Overview</h3>
                  </div>
                  <div className="square-confirm-4">
                    {pricingFormat === "breakdown" ? (
                      <>
                        <div className="price-row-confirm">
                          <p>Base Rate (HourlyRate * ${vehicle?.minHoursOfBooking} Hour)</p>
                          <p>${basePrice.toFixed(2)}</p>
                        </div>
                        <div className="price-row-confirm">
                          <p>Gratuity (20.00%)</p>
                          <p>${gratuity.toFixed(2)}</p>
                        </div>
                        <div className="price-row-confirm">
                          <p>Tax (10.25%)</p>
                          <p>${tax.toFixed(2)}</p>
                        </div>
                        <div className="price-row-confirm">
                          <p>Car Seat</p>
                          <p>${carSeat.toFixed(2)}</p>
                        </div>
                        <div className="price-row-confirm">
                          <p>Waiting Time</p>
                          <p>${waitingTime.toFixed(2)}</p>
                        </div>
                        <div className="price-row-confirm">
                          <p>Admin Fee</p>
                          <p>${adminFee.toFixed(2)}</p>
                        </div>
                        <div className="price-row-confirm">
                          <p>Toll</p>
                          <p>${toll.toFixed(2)}</p>
                        </div>
                        {reservation.tripType === "Round Trip" && (
                          <div className="price-row-confirm">
                            <p>One Way</p>
                            <p>${total.toFixed(2)}</p>
                          </div>
                        )}
                        <hr className="total-divider" />
                        <div className="price-row-confirm">
                          <p style={{ fontWeight: "bold", fontSize: "20px" }}>Total</p>
                          <p style={{ fontWeight: "bold", fontSize: "20px", color: "#058CBD" }}>
                            {couponApplied ? (
                              <>
                                <span style={{ textDecoration: "line-through", color: "red" }}>
                                  ${reservation.tripType === "Round Trip" ? (oldTotal * 2).toFixed(2) : oldTotal.toFixed(2)}
                                </span>
                                &nbsp;
                                <span>
                                  ${reservation.tripType === "Round Trip" ? (total * 2).toFixed(2) : total.toFixed(2)}
                                </span>
                              </>
                            ) : (
                              `${reservation.tripType === "Round Trip" ? (total * 2).toFixed(2) : total.toFixed(2)}`
                            )}
                          </p>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="price-row-confirm">
                          <p style={{ fontWeight: "bold", fontSize: "20px" }}>Total</p>
                          <p style={{ fontWeight: "bold", fontSize: "20px", color: "#058CBD" }}>
                            {couponApplied ? (
                              <>
                                <span style={{ textDecoration: "line-through", color: "red" }}>
                                  ${reservation.tripType === "Round Trip" ? (oldTotal * 2).toFixed(2) : oldTotal.toFixed(2)}
                                </span>
                                &nbsp;
                                <span>
                                  ${reservation.tripType === "Round Trip" ? (total * 2).toFixed(2) : total.toFixed(2)}
                                </span>
                              </>
                            ) : (
                              `${reservation.tripType === "Round Trip" ? (total * 2).toFixed(2) : total.toFixed(2)}`
                            )}
                          </p>
                        </div>
                        <hr className="total-divider" />
                      </>
                    )}

                    <div className="promo-code-confirm">
                      <label htmlFor="promo-code">Coupon</label>
                      <div className="d-flex flex-row">
                        <input
                          className="form-control"
                          type="text"
                          id="promo-code"
                          value={coupon}
                          onChange={handleCouponChange}
                          placeholder="Enter Coupon"
                        />
                        <button
                          className="ms-2"
                          style={{
                            width: '35%',
                            height: '43px',
                            padding: '15.11px, 31.31px, 16.89px, 30.23px',
                            borderRadius: '7.11px',
                            backgroundColor: '#058CBD',
                            border: '1px solid #058CBD',
                            color: '#FFFFFF',
                            fontSize: '18px',
                          }}
                          onClick={handleApplyCouponChange}
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="confirm-square">
                <div className="w-100 mb-2 mt-2">
                  <h3>Booking Contact</h3>
                </div>
                <div className="square-confirm-4">
                  <div className="info-row-field">
                    <label htmlFor="mobile-number">Mobile Number</label>
                    <PhoneInput
                        type="number"
                        name="phoneNumber"
                        id="phoneNumber"
                        placeholder="Enter Phone Number"
                        value={reservation.passenger_details?.phone || clientDetails?.phone}
                        // onChange={(value) => handlePhoneNumberChange(value)}
                        onChange={(value) => {
                          handleChange("passenger_details", {
                              ...reservation.passenger_details,
                              phone: value,
                          });
                          setClientDetails({
                            phone: value,
                          });
                          if (reservation.tripType === "Round Trip") {
                            handleChange2("passenger_details", {
                              ...roundtrip.passenger_details,
                              phone: value,
                            });
                          }
                        }}
                        country={"us"}
                        inputProps={{
                            name: "phoneNumber",
                            required: true,
                        }}
                        inputStyle={{
                            width: "100%",
                            height: "43px",
                            borderRadius: "4px",
                            border: "1px solid #ccc",
                        }}
                        className="mb-2"
                    />
                                                    
                    <label htmlFor="email">Enter Email</label>
                    <input 
                      className="input-field form-control" 
                      type="email" 
                      id="email"
                      placeholder="Enter your email" // Set your placeholder text here
                      value={reservation.passenger_details?.email || clientDetails?.email}
                      onChange={(e) => {
                        handleChange("passenger_details", {
                            ...reservation.passenger_details,
                            email: e.target.value,
                        });
                        setClientDetails({
                          email: e.target.value,
                        });
                        if (reservation.tripType === "Round Trip") {
                          handleChange2("passenger_details", {
                            ...roundtrip.passenger_details,
                            email: e.target.value,
                          });
                        }                    
                      }}
                    />
                    <label htmlFor="firstname">First Name</label>
                    <input
                      className="input-field form-control"
                      type="firstname"
                      id="firstname"
                      placeholder="Enter your First Name" // Set your placeholder text here
                      value={reservation.passenger_details?.firstName || clientDetails?.firstName}
                      onChange={(e) => {
                        handleChange("passenger_details", {
                            ...reservation.passenger_details,
                            firstName: e.target.value,
                        });
                        setClientDetails({
                          firstName: e.target.value,
                        });
                        if (reservation.tripType === "Round Trip") {
                          handleChange2("passenger_details", {
                            ...roundtrip.passenger_details,
                            firstName: e.target.value,
                          });
                        }                       
                      }}
                    />
                    <label htmlFor="lastname">Last Name</label>
                    <input
                      className="input-field form-control"
                      type="lastname"
                      id="lastname"
                      placeholder="Enter your Last Name" // Set your placeholder text here
                      value={reservation.passenger_details?.lastName || clientDetails?.lastName}
                      onChange={(e) => {
                        handleChange("passenger_details", {
                            ...reservation.passenger_details,
                            lastName: e.target.value,
                        });
                        setClientDetails({
                          lastName: e.target.value,
                        });
                        if (reservation.tripType === "Round Trip") {
                          handleChange2("passenger_details", {
                            ...roundtrip.passenger_details,
                            lastName: e.target.value,
                          });
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
        {/* <div className="button-container d-flex align-items-center justify-content-center me-5">
          <Checkbox 
              style={{
                fontSize: '17.78px',
                padding: '10px'
              }}
          >
            Agree to terms & conditions.
          </Checkbox>
        </div> */}
        <div className="button-container d-flex align-items-center justify-content-center me-5">
          <Checkbox
            style={{
              fontSize: '17.78px',
              padding: '10px'
            }}
          >            
          </Checkbox>

          <span
            onClick={showModal}
            style={{
              fontSize: '17.78px',
              paddingLeft: '10px',
              cursor: 'pointer',
              color: '#1890ff'
            }}
          >
            Agree to terms & conditions.
          </span>

          <Modal
            title={terms?.title || 'Terms and Conditions'}
            visible={isModalVisible}
            onOk={handleClose}
            onCancel={handleClose}
            footer={null}
            centered
          >

            <div dangerouslySetInnerHTML={{ __html: terms?.content || '' }} />
          </Modal>
        </div>
        {/* <div className="button-container d-flex align-items-center justify-content-center mb-4 ">
          <button className="button-reserve me-4" type="button" onClick={() => handleSave("now")}>
            Pay Now
          </button>
          <button className="button-reserve" type="button" onClick={() => handleSave("later")}>
            Pay Later
          </button>
        </div>   */}
        <div className="button-container d-flex align-items-center justify-content-center mb-4">
          {payOption === "payNow" ? (
            <button className="button-reserve" type="button" onClick={() => handleSave("now")} style={{
              backgroundColor: 'rgb(5, 140, 189)', // Set button color
              borderColor: 'rgb(5, 140, 189)', // Ensure border matches button color
              paddingTop: '10px'
            }}>
              Pay Now
            </button>
          ) : payOption === "both" ? (
            <>
              <button className="button-reserve me-4" type="button" onClick={() => handleSave("now")} style={{
                backgroundColor: 'rgb(5, 140, 189)', // Set button color
                borderColor: 'rgb(5, 140, 189)', // Ensure border matches button color
                paddingTop: '10px'
              }}>
                Pay Now
              </button>
              <button className="button-reserve" type="button" onClick={() => handleSave("later")} style={{
                backgroundColor: 'rgb(5, 140, 189)', // Set button color
                borderColor: 'rgb(5, 140, 189)', // Ensure border matches button color
              }}>
                Pay Later
              </button>
            </>
          ) : null}
        </div>
      </div>
    </>
  );
}

export default Confirm;
