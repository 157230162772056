import React, { useEffect, useState } from "react";
import Nav from "../navbar/navigation";
import "./back.css";
import Car2 from "../formsCard/car2.png";
import FormContainer from "./forminfo";
import Choosevehicle from "./choosevehicle";
import Byname from "./byname";
import Confirm from "./confirm";
import Stepper1 from "../img/stepper1.svg";
import Stepper2 from "../img/stepper2.svg";
import Stepper3 from "../img/stepper3.svg";
import Back from "../img/back.svg";
import { Steps, Checkbox, Modal } from "antd";
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import {
  getCompany,
  selectCompany,
} from "../../redux/feature/company/slice";
import SignIn from "./SignIn/SignIn";
const { Step } = Steps;
function Background() {
  const dispatch = useDispatch();
  const { companyId } = useParams();
  const [currentStep, setCurrentStep] = useState(1);
  const [isToggleOn, setIsToggleOn] = useState(true);
  const [formData, setFormData] = useState({});
  const [vehicleData, setVehicleData] = useState({});
  const [isByNameChecked, setIsByNameChecked] = useState(false);
  const [isByTypeChecked, setIsByTypeChecked] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const company = useSelector(selectCompany);
  // Assuming company has `backgroundColor` and `textColor`
  const backgroundColor = company?.backgroundColor || '#058CBD'; // Default
  const textColor = company?.textColor || '#FFFFFF'; // Default
  console.log("Company Here:", company);

  useEffect(() => {
    dispatch(getCompany(companyId));
  }, [companyId, dispatch]);
  useEffect(() => {
    if (backgroundColor && textColor) {

      document.documentElement.style.setProperty('--btn-bg-color', backgroundColor);
      document.documentElement.style.setProperty('--btn-text-color', textColor);
      document.documentElement.style.setProperty('--text-color', textColor);

    }
  }, [backgroundColor, textColor]);
  const handleToggle = () => {
    setIsToggleOn(!isToggleOn); // Toggle the state
  };

  const handleStepClick = (clickedStep) => {
    setCurrentStep(clickedStep);
  };

  const goNext = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const goBack = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const goNextData = (Data) => {
    setFormData(Data);
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const goNextVehicle = (vehicle) => {
    setVehicleData(vehicle);
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handleByNameChange = (e) => {
    setIsByNameChecked(e.target.checked);
    setIsByTypeChecked(!e.target.checked); // Ensure only one checkbox is checked at a time
  };

  const handleByTypeChange = (e) => {
    setIsByTypeChecked(e.target.checked);
    setIsByNameChecked(!e.target.checked); // Ensure only one checkbox is checked at a time
  };

  const renderStepperImage = () => {
    switch (currentStep) {
      case 1:
        return <img src={Stepper1} alt="Step 1" className="img-fluid img-responsive" />;
      case 2:
        return <img src={Stepper2} alt="Step 2" className="img-fluid img-responsive" />;
      case 3:
        return <img src={Stepper3} alt="Step 3" className="img-fluid img-responsive" />;
      default:
        return null;
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  // Function to handle step change (AntD Steps component automatically handles it)
  const handleStepChange = (newStep) => {
    // Ensure we stay within bounds (0 to steps.length - 1)
    if (newStep >= 0 && newStep < steps.length) {
      setCurrentStep(newStep + 1);
    }
  };
  const handleNext = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handlePrev = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };
  const steps = [
    {
      title: 'Request Info',
      content: <FormContainer goNext={goNextData} hours={company?.bookingHours} />,
    },
    {
      title: 'Choose Vehicle',
      content: (
        <>
          <div className="d-flex flex-wrap justify-content-between align-items-center w-100 mb-3">
            <div className="d-flex justify-content-start ms-5">
              <h1>Popular Cars</h1>
            </div>
          </div>
          {formData ? (
            <Choosevehicle
              goNext={goNextVehicle}
              formData={formData}
              companyId={companyId}
            />
          ) : (
            <Byname
              goNext={goNextVehicle}
              formData={formData}
              companyId={companyId}
            />
          )}
        </>
      ),
    },
    {
      title: 'Confirm',
      content: (
        <Confirm
          vehicle={vehicleData}
          ConnAccId={company?.stripeId}
          CompanyId={company?._id}
          CreditCard={company?.requireCC}
          DBA={company?.dbas}
          URL={company?.bookingThankYouPage}
          pricingFormat={company?.pricingFormat}
          payOption={company?.payOption}
        />
      ),
    },
  ];

  return (
    <div className="container-fluid p-2">
      <div
        className="container position-relative bg-white rounded responsive-scale"
      >
        <div className="row ms-md-5 ms-0">
          <div className="col-md-12 text-center">
            <img
              src={company?.banner || "https://firebasestorage.googleapis.com/v0/b/dooms-prod.appspot.com/o/widget_photos%2F8I769QDA_IMG_7644.jpg?alt=media&token=8567b233-08f5-438d-8679-365c58c1f4e9"}
              alt="New Image"
              className="img-fluid img-responsive company-banner"
            // style={{ maxHeight: '300px', width: 'auto' }} 
            />
          </div>
        </div>

        <div className="row mt-5 align-items-center me-md-5 me-0">
          <div className="col-md-4 text-center text-md-start d-flex justify-content-center">
            <div className="logo-container  align-items-center">
              <img
                src={company?.logo}
                alt="Company Logo"
                className="company-logo img-fluid img-responsive"
                style={{ maxWidth: '120px', height: 'auto' }}
              />
            </div>

          </div>
          <div className="col-md-4 text-center my-2 my-md-0">
            <h1 className="font-weight-600" style={{ fontSize: '1.8rem' }}>{company?.name || "Xtreim"}</h1>
          </div>
          <div className="col-md-4 text-center text-md-end">
            <button
              className="btn btn-primary"
              style={{
                backgroundColor: 'rgb(5, 140, 189)',
                color: 'white',
                borderRadius: '8px',
                fontSize: '16px',
                padding: '12px 20px',
                border: 'none'
              }}
              onClick={showModal}
            >
              LOGIN
            </button>
          </div>
        </div>
        <div className="stepper-container d-flex flex-row flex-md-column align-items-center my-5">
          <Steps
            current={currentStep - 1}
            labelPlacement="vertical"
            onChange={setCurrentStep}
            // size={"small"}
            responsive={true}
            className="w-50"
            size="default"
            style={{ width: '800px' }}
          >
            {steps.map((step, index) => (
              <Step key={index} title={step.title} />
            ))}
          </Steps>

          {/* <div className="steps-content">{steps[currentStep]?.content}</div>
          <div className="steps-action">
            {currentStep < steps.length - 1 && (
              <button className="btn btn-primary" onClick={handleNext}>
                Next
              </button>
            )}
            {currentStep > 0 && (
              <button className="btn btn-secondary" onClick={handlePrev}>
                Previous
              </button>
            )}
          </div> */}
        </div>
        <div className="stepper-container text-center my-5">
          <div className="d-flex align-items-center justify-content-start">
            {currentStep ==3  && (
              <div className="">
                <img
                  src={Back}
                  alt="Back"
                  className="img-fluid img-responsive"
                  style={{ width: '27px', height: '27px', cursor: 'pointer', marginRight: '30px', marginTop: '8px' }}
                  onClick={goBack}
                />
              </div>
            )}
            {/* <div className="mx-auto">
                {renderStepperImage()}
              </div> */}
          </div>
        </div>
        {currentStep === 1 && <FormContainer goNext={goNextData} hours={company?.bookingHours} />}
        {currentStep === 2 && (
          <>
            <div className="d-flex flex-wrap justify-content-between align-items-center w-100 mb-3">
              <div className="d-flex justify-content-start ms-5">
              {currentStep >= 2 && (
              <div className="">
                <img
                  src={Back}
                  alt="Back"
                  className="img-fluid img-responsive"
                  style={{ width: '27px', height: '27px', cursor: 'pointer', marginRight: '30px', marginTop: '8px' }}
                  onClick={goBack}
                />
              </div>
            )}
                <h1>Popular Cars</h1>
              </div>
              {/* <div className="d-flex align-items-center justify-content-end me-5">
                <Checkbox 
                  checked={isByNameChecked} 
                  onChange={handleByNameChange}
                  style={{
                    fontSize: '17.78px',
                    padding: '10px'
                  }}
                >
                  By Type
                </Checkbox>
                <Checkbox 
                  checked={isByTypeChecked} 
                  onChange={handleByTypeChange}
                  style={{
                    fontSize: '17.78px'
                  }}
                >
                  By Name
                </Checkbox>
              </div> */}
            </div>
            {isByNameChecked ?
              <Choosevehicle
                goNext={goNextVehicle}
                formData={formData}
                companyId={companyId}
              />
              :
              <Byname
                goNext={goNextVehicle}
                formData={formData}
                companyId={companyId}
              />
            }
          </>
        )}
        {currentStep === 3 &&
          <Confirm
            vehicle={vehicleData}
            ConnAccId={company?.stripeId}
            CompanyId={company?._id}
            CreditCard={company?.requireCC}
            DBA={company?.dbas[0]}
            URL={company?.bookingThankYouPage}
            pricingFormat={company?.pricingFormat}
            payOption={company?.payOption}
          />
        }
      </div>
      <br />
      <Modal
        title="Sign In"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        centered
      >
        <SignIn onClose={handleCancel} companyId={company?._id} />
      </Modal>
    </div>
  );
}

export default Background;
